import React, { useEffect, useState, useMemo } from "react";
import { TableHeader, Pagination, Search } from "../../DataTable/";
import Nav from '../../Nav/Nav';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from '../../../contexts/useAuth';
    
const OfficeStaff = () => {
        const { userData } = useAuth();
        const [comments, setComments] = useState([]);
        const [data, setData] = useState('10');
        const [totalItems, setTotalItems] = useState(0);
        const [currentPage, setCurrentPage] = useState(1);
        const [search, setSearch] = useState("");
        const [sorting, setSorting] = useState({ field: "", order: "" });
        const [updateData, setUpdateData] = useState([]);
        const type = 'Office Staff';

        const successNotify = () =>toast.success('Data Updated Successfully');
        const failNotify = () =>toast.error('Data Updated Fail');
        const successDeleteNotify = () =>toast.success('Data Deleted Successfully');
    
        const ITEMS_PER_PAGE = data;
    
        const headers = [
            { name: "ক্রমিক", field: "", sortable: false },
            { name: "নাম", field: "name", sortable: true },
            { name: "ঠিকানা", field: "address", sortable: true },
            { name: "মোবাইল", field: "mobile", sortable: true },
            { name: "মেয়াদ কাল", field: "meyad", sortable: true }, 
            { name: "বিস্তারিত", field: "details", sortable: true }, 
            { name: "অপশন", field: "", sortable: false }, 
        ];
    
        useEffect(() => {
            getData();
        });
    
        function getData() {
            fetch(`https://api-union.intelligentsoftech.com/upStaffData/${type}/${userData.ID}`)
                    .then(response => response.json())
                    .then(json => {
                        setComments(json);
            });
        }
    
        const commentsData = useMemo(() => {
            let computedComments = comments;
    
            if (search) {
                computedComments = computedComments.filter(
                    comment =>
                        comment._id.toLowerCase().includes(search.toLowerCase()) ||
                        comment.name.toLowerCase().includes(search.toLowerCase()) ||
                        comment.address.toLowerCase().includes(search.toLowerCase()) ||
                        comment.mobile.toLowerCase().includes(search.toLowerCase()) ||
                        comment.meyad.toLowerCase().includes(search.toLowerCase()) ||
                        comment.details.toLowerCase().includes(search.toLowerCase())
                );
            }
    
            setTotalItems(computedComments.length);
    
            //Sorting comments
            if (sorting.field) {
                const reversed = sorting.order === "asc" ? 1 : -1;
                computedComments = computedComments.sort(
                    (a, b) =>
                        reversed * a[sorting.field].localeCompare(b[sorting.field])
                );
            }
    
            //Current Page slice
            return computedComments.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }, [comments, currentPage, search, sorting, ITEMS_PER_PAGE]);



        const handleOnSubmit = e =>{
            e.preventDefault();
            const name    = e.target.elements.name.value;   
            const address = e.target.elements.address.value;  
            const mobile  = e.target.elements.mobile.value;  
            const meyad   = e.target.elements.meyad.value;  
            const details = e.target.elements.details.value;  

            const infoData = {
                upCode: userData.ID,
                type,
                name,
                address,
                mobile,
                meyad,
                details,
            }

            //console.log(infoData);
    
            fetch('https://api-union.intelligentsoftech.com/insertUpStaffData', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(infoData)
            })
            .then(res=>res.json())
            .then(data=>{
                if(data.insertedId){
                    e.target.reset();
                    successNotify();
                    getData();
                }else{
                    failNotify();
                }
            })
        }

        const updateHandle = e =>{
            setUpdateData(e);
        }

        const handleOnUpdate = e =>{
            e.preventDefault();
            const id      = e.target.elements.id.value;
            const name    = e.target.elements.name.value;   
            const address = e.target.elements.address.value;  
            const mobile  = e.target.elements.mobile.value;  
            const meyad   = e.target.elements.meyad.value;  
            const details = e.target.elements.details.value;  

            const infoData = {
                id,
                name,
                address,
                mobile,
                meyad,
                details,
            }

            //console.log(infoData);
    
            fetch('https://api-union.intelligentsoftech.com/updateUpStaffData', {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(infoData)
            })
            .then(res=>res.json())
            .then(data=>{
                if(data.acknowledged){
                    successNotify();
                    getData();
                }else{
                    failNotify();
                }
            })
        }

        const deleteHandle = id =>{
            const proceed = window.confirm('Are you sure to Delete?');
            if(proceed){
              const url = `https://api-union.intelligentsoftech.com/deleteUpStaffData/${id}`
              fetch(url, {
                method: 'DELETE'
              })
              .then(res=>res.json())
              .then(data=>{
                if(data.deletedCount>0){
                    successDeleteNotify();
                  const remaining = comments.filter(row=>row._id!==id);
                    setComments(remaining);
                }
              })
            }
        }

        let count = 1;
    
        return (
            <div>
    
                <Nav></Nav>

                <Toaster
                position='top-right'
            />
    
                <div className="container shadow mt-3 mb-3">
    
                    <div className="d-grid gap-2 d-md-block">
                        <button className="btn mt-2 btn-success font-15" style={{ marginRight: '4px' }} data-bs-toggle="modal" data-bs-target="#exampleModal1" type="button">Add {type} Data</button>
                    </div> 
    
                    <div className="mt-2 p-2"> 
                        <div className="row">
                            <div className="col mb-3 col-12 text-center">
                                <div className="row">
    
                                    <div className="col-md-3 mb-2">
                                        <select className="form-select bangla-font" onClick={e => setData(e.target.value)} aria-label="Default select example">
                                            <option value="50">প্রতি পেজে ডাটার সংখা</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
    
                                    <div className="col-md-6">
                                    
                                    </div>
                          
                                    <div className="col-md-3 mb-2 d-flex flex-row-reverse">
                                        <Search
                                            onSearch={value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </div>
                                </div>
    
                                <div className="table-responsive">               
                                    <table className="table table-striped mb-3" style={{ width:'100%' }}>
                                        <TableHeader
                                            headers={headers}
                                            onSorting={(field, order) =>
                                                setSorting({ field, order })
                                            }
                                        />
                                        <tbody>
                                            {commentsData.map(comment => (
                                                <tr key={comment._id}>
                                                    <td>{count++}</td>
                                                    <td>{comment.name}</td>
                                                    <td>{comment.address}</td>
                                                    <td>{comment.mobile}</td>
                                                    <td>{comment.meyad}</td>
                                                    <td>{comment.details}</td>
                                                    <td> 
                                                        <div className="dropdown">
                                                            <button className="btn btn-danger btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            অপশন
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><Link className="dropdown-item custom-dropdown" onClick={()=> updateHandle(comment)} data-bs-toggle="modal" data-bs-target="#exampleModal2" to="#">আপডেট</Link></li>
                                                                {
                                                                    userData.type === 'Admin' && <li><Link className="dropdown-item custom-dropdown" onClick={()=> deleteHandle(comment._id)} to="#">ডিলেট</Link></li>
                                                                }
                                                            </ul>
                                                        </div>  
                                                    </td> 
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>    
                                <div className="row">
                                    <div className="col-md-6">
                                        <Pagination
                                            total={totalItems}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    </div>
                                    <div className="col-md-6 d-flex flex-row-reverse">
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

               
                <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                        <div className="modal-header bg-danger text-light">
                            <h5 className="modal-title" id="exampleModalLabel">Add New Data</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleOnSubmit}>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">নাম</label>
                                    <input type="text" name="name" className="form-control" placeholder="নাম" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">ঠিকানা</label>
                                    <input type="text" name="address" className="form-control" placeholder="ঠিকানা" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">মোবাইল</label>
                                    <input type="text" name="mobile" className="form-control" placeholder="মোবাইল"/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">মেয়াদ কাল</label>
                                    <input type="text" name="meyad" className="form-control" placeholder="মেয়াদ কাল"/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">বিস্তারিত</label>
                                    <textarea className="form-control" name="details" rows="2"></textarea>
                                </div>
                                <button type="submit" className="btn btn-danger">Submit</button>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-scrollable">
                        <div className="modal-content">
                        <div className="modal-header bg-danger text-light">
                            <h5 className="modal-title" id="exampleModalLabel">Update Data</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleOnUpdate}>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">নাম</label>
                                    <input type="text" name="name" className="form-control" defaultValue={updateData.name} placeholder="নাম" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">ঠিকানা</label>
                                    <input type="text" name="address" className="form-control" defaultValue={updateData.address} placeholder="ঠিকানা" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">মোবাইল</label>
                                    <input type="text" name="mobile" className="form-control" defaultValue={updateData.mobile} placeholder="মোবাইল"/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">মেয়াদ কাল</label>
                                    <input type="text" name="meyad" className="form-control" defaultValue={updateData.meyad} placeholder="মেয়াদ কাল"/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">বিস্তারিত</label>
                                    <textarea className="form-control" name="details" rows="2" defaultValue={updateData.details}></textarea>
                                </div>
                                <input type="hidden" name="id" defaultValue={updateData._id}/>
                                <button type="submit" className="btn btn-danger">Update</button>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>

          
                
    
            </div>
        );
    };
    
export default OfficeStaff;
