import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../contexts/useAuth';
    
const PrintWarishSonod = () => {
    const { userData } = useAuth();
    const { profileId }  = useParams();
    const { Id }  = useParams();
    const [ profile, setProfile] = useState({});
    const [ data, setData ] = useState([]);

    const id = userData.ID;

        useEffect(() =>{
            fetch(`https://api-union.intelligentsoftech.com/warishDataSingle/${profileId}`)
            .then(res=>res.json())
            .then(data=>setProfile(data));
        }, [profileId])
    
        useEffect(() => {
            getData();
        });
    
        function getData() {
            fetch(`https://api-union.intelligentsoftech.com/upinfo2/${id}`)
            .then(response => response.json())
            .then(json => setData(json));
        }
                  
        return (
            <div>
    
                <div className="print-area2" onClick={()=> window.print() }>
                <div className="mb-4 p-2" style={{  }}>
                <div className="">
                {
                    data.map((row)=>(  
                        <>
                            <div className="row" style={{ borderBottom: '10px solid #EB3742', backgroundColor: '#FFF3E3' }}>
                                <div className="col-sm-12">
                                    <div className="" style={{ }}>
                                        <div style={{ color: '#3A3462', fontSize: '35px', padding: '0px' }}>
                                            <img src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`} style={{ width: '90px', marginLeft:'20px', marginTop: '10px' }} alt="" />
                                            <b>&nbsp; {row.upFullName}</b>
                                        </div>
                                        <div className='text-center' style={{ color: '#3A3462', fontSize: '25px', marginTop:'-30px' }}><b>ডাকঘরঃ {row.postOffice}, উপজেলাঃ {row.upzila}, জেলাঃ {row.jella} ।</b></div>
                                        <h4 className='text-center mb-2 p-1' style={{ border: '2px solid black', borderRadius: '20px', width: '380px', color: 'black', backgroundColor: '', margin: 'auto' }}><b>চেয়ারম্যানঃ {row.chairmanName} </b></h4>
                                        <h5 className='text-center' style={{ fontSize: '23px', fontFamily: 'Arial' }}><b>E-mail: {row.mail}</b></h5>
                                    </div>
                                </div>
                            </div>
                            
                            <br/>
    
                            <div className="demo-wrap2">
                                    <img
                                        className="demo-bg2"
                                        src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`}
                                        alt=""
                                    />
                                    <div className="demo-content2" style={{ height: '900px' }}>
                                        <div className="row font-20">
                                            <div className="col-sm-8">
                                                <p>&nbsp; &nbsp; ক্রমিক নম্বরঃ <b>{ Id }</b></p>
                                            </div>
                                            <div className="col-sm-4">
                                                <p>তারিখঃ<span className='border-print'>&nbsp; &nbsp; <b>{ profile.entryDate } খ্রি.</b> &nbsp; </span></p>
                                            </div>
                                            <div className="col-sm-12 mb-4">
                                                <h2 className='text-center mb-2 p-1' style={{ border: '2px solid black', borderRadius: '20px', width: '280px', color: 'black', backgroundColor: '', margin: 'auto' }}><b>ওয়ারিশ সনদ</b></h2>
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginLeft: '40px', marginRight: '40px' }}>
                                            <div className="col-sm-12">
                                                <p> &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; এই মর্মে প্রত্যয়ন করা যাইতেছে যে, <strong className='border-print'>&nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  <b>{ profile.name }</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  </strong> </p>
                                            </div>
                                            <div className="col-sm-6">   
                                                <p style={{ marginLeft: '25px' }}>পিতা/স্বামীঃ  <strong className='border-print'> &nbsp; &nbsp; <b>{profile.father}</b> &nbsp; &nbsp;  &nbsp;  </strong></p>
                                            </div>
                                            <div className="col-sm-6">
                                                <p>গ্রামঃ <strong className='border-print'>&nbsp; &nbsp; &nbsp; &nbsp;  <b>{profile.village}</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong></p>
                                            </div>
                                            <div className="col-sm-12">
                                                <p style={{ marginLeft: '25px' }}>ডাকঘরঃ <strong className='border-print'>&nbsp; &nbsp; <b> {row.postOffice} </b> &nbsp; &nbsp; </strong> উপজেলাঃ <b>{row.upzila}</b>, &nbsp; জেলাঃ <b>{row.jella}</b> । তিনি অত্র ইউনিয়নের <strong className='border-print'> &nbsp; &nbsp; <b>{ profile.wordNumber }</b> &nbsp; &nbsp; </strong> নং ওয়ার্ডের স্থায়ী বাসিন্দা ছিলেন।  </p>
                                                <p style={{ marginLeft: '25px' }}> { profile.status } তিনি নিম্নলিখিত ওয়ারিশ/ওয়ারিশগণকে রেখে যান। </p>
                                            </div>
                                        </div>

                                        <div className="mb-3" style={{ marginLeft: '80px', marginRight: '80px' }}>
                                            <table style={{ width: '100%' }}>
                                                <tr style={{ textAlign: 'center' }}>
                                                    <th style={{ width: '60px'}}>ক্রমিক নম্বর</th>
                                                    <th style={{ marginLeft: '20px' }}>নাম</th>
                                                    <th>সম্পর্ক</th>
                                                    <th style={{ width: '100px' }}>আনুমানিক বয়স</th>
                                                    <th>মন্তব্য</th>
                                                </tr>
                                                
                                                {
                                                    profile.name1 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>০১</td>
                                                        <td>&nbsp;&nbsp; {profile.name1}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation1}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age1}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment1}</td>
                                                    </tr>
                                                    
                                                }
                                                {
                                                    profile.name2 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>০২</td>
                                                        <td>&nbsp;&nbsp; {profile.name2}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation2}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age2}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment2}</td>
                                                    </tr>                                                   
                                                }

                                                {
                                                    profile.name3 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>০৩</td>
                                                        <td>&nbsp;&nbsp;{profile.name3}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation3}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age3}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment3}</td>
                                                    </tr>                                                 
                                                }

                                                {
                                                    profile.name4 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>০৪</td>
                                                        <td>&nbsp;&nbsp;{profile.name4}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation4}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age4}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment4}</td>
                                                    </tr>                                               
                                                }

                                                {
                                                    profile.name5 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>০৫</td>
                                                        <td>&nbsp;&nbsp;{profile.name5}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation5}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age5}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment5}</td>
                                                    </tr>                                           
                                                }
                                                                              
                                                
                                                {
                                                    profile.name6 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>০৬</td>
                                                        <td>&nbsp;&nbsp;{profile.name6}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation6}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age6}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment6}</td>
                                                    </tr> 
                                                }

                                                {
                                                    profile.name7 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>০৭</td>
                                                        <td>&nbsp;&nbsp;{profile.name7}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation7}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age7}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment7}</td>
                                                    </tr>
                                                }

                                                {
                                                    profile.name8 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>০৮</td>
                                                        <td>&nbsp;&nbsp;{profile.name8}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation8}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age8}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment8}</td>
                                                    </tr>
                                                }

                                                {
                                                    profile.name9 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>০৯</td>
                                                        <td>&nbsp;&nbsp;{profile.name9}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation9}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age9}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment9}</td>
                                                    </tr>
                                                }

                                                {
                                                    profile.name10 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>১০</td>
                                                        <td>&nbsp;&nbsp;{profile.name10}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation10}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age10}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment10}</td>
                                                    </tr>
                                                }
                                                
                                                {
                                                    profile.name11 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>১১</td>
                                                        <td>&nbsp;&nbsp;{profile.name11}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation11}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age11}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment11}</td>
                                                    </tr>
                                                    
                                                }
                                                {
                                                    profile.name12 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>১২</td>
                                                        <td>&nbsp;&nbsp;{profile.name12}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation12}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age12}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment12}</td>
                                                    </tr>
                                                    
                                                }

                                                {
                                                    profile.name13 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>১৩</td>
                                                        <td>&nbsp;&nbsp;{profile.name13}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation13}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age13}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment13}</td>
                                                    </tr> 
                                                }

                                                {
                                                    profile.name14 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>১৪</td>
                                                        <td>&nbsp;&nbsp;{profile.name14}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation14}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age14}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment14}</td>
                                                    </tr>
                                                }

                                                {
                                                    profile.name15 &&
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }}>১৫</td>
                                                        <td>&nbsp;&nbsp;{profile.name15}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.relation15}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.age15}</td>
                                                        <td style={{ textAlign: 'center' }}>{profile.comment15}</td>
                                                    </tr>
                                                }
                                                       
                                            </table>
                                        </div>
                                    
                                   <br/><br/>                             
                                   
                                    <div className="row">
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-4"></div>
                                        <div className="col-sm-5 text-center">
                                            <div className="">{row.chairmanName}</div>
                                            <div className="" style={{ marginTop: '-6px' }}>চেয়ারম্যান</div>
                                            <div className="" style={{ marginTop: '-6px' }}>{row.upName}</div>
                                            <div className="" style={{ marginTop: '-6px' }}>{row.upzila}, {row.jella}</div>
                                        </div>
                                    </div>
                                
                                </div>
                        </div>
                        </>
                    ))
                }
                </div>
                </div>
                </div>
            </div>
        );
    };
    
export default PrintWarishSonod;