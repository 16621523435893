import React, { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import Nav from '../Nav/Nav';
import useAuth from '../../contexts/useAuth';

const UPInfo = () => {
    const {userData} = useAuth();
    const [info, setInfo] = useState({});

    const successNotify = () =>toast.success('Data Inserted Successfully');
    const failNotify = () =>toast.error('Data Inserted Fail');
    //const successDeleteNotify = () =>toast.success('Data Deleted Successfully');

    const handleOnSubmit = e =>{
        const upFullName = e.target.elements.upFullName.value;
        const chairmanName = e.target.elements.chairmanName.value;
        const sochibName = e.target.elements.sochibName.value;
        const upName = e.target.elements.upName.value;
        const upNumber = e.target.elements.upNumber.value;
        const postOffice = e.target.elements.postOffice.value;
        const upzila = e.target.elements.upzila.value;
        const jella = e.target.elements.jella.value;
        const totalWord = e.target.elements.totalWord.value;
        const totalVillage = e.target.elements.totalVillage.value;
        const sarokNumber = e.target.elements.sarokNumber.value;
        const mail   = e.target.elements.mail.value;
        const people = e.target.elements.people.value;
        const male   = e.target.elements.male.value;
        const female = e.target.elements.female.value;
        const totalVotar = e.target.elements.totalVotar.value;

        //-- 2009, 5 August --
        const infoData = {
            id: userData.ID,
            upFullName,
            chairmanName,
            sochibName,
            upName,
            upNumber,
            postOffice,
            upzila,
            jella,
            totalWord,
            totalVillage,
            sarokNumber,
            mail,
            people,
            male,
            female,
            totalVotar,
        }

        //console.log(infoData);
        
        fetch('https://api-union.intelligentsoftech.com/upInfoData', {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(infoData)
        })
        .then(res=>res.json())
        .then(data=>{
            if(data.acknowledged){
                //e.target.reset();
                successNotify();
                getData();
            }else{
                failNotify();
            }
        })

        e.preventDefault();
    }

    useEffect(() =>{
        getData();
    });

    function getData(){
        fetch(`https://api-union.intelligentsoftech.com/upinfo/${userData.ID}`)
        .then(response => response.json())
        .then(json => setInfo(json));
    }

    return (
        <div>
            <Nav/>
            <Toaster
                position='top-right'
            />
            <div className="container mt-3 mb-3">
                <div className="card shadow">
                    <div className="card-header bg-primary text-light font-16">ইউনিয়ন পরিষদ তথ্য</div>

                    <div className="card-body">
                
                            <form onSubmit={handleOnSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label font-15">ইউনিয়ন পরিষদের পূর্ণ নাম </label>
                                        <input type="text" name="upFullName" className="form-control font-15" placeholder="ইউনিয়ন পূর্ণ নাম" defaultValue={info.upFullName} />
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label font-15">চেয়ারম্যানের নাম</label>
                                        <input type="text" name="chairmanName" className="form-control font-15" placeholder="চেয়ারম্যানের নাম" defaultValue={info.chairmanName} />
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label font-15">সচিবের নাম</label>
                                        <input type="text" name="sochibName" className="form-control font-15" placeholder="সচিবের নাম" defaultValue={info.sochibName}/>
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label font-15">ইউনিয়নের নাম</label>
                                        <input type="text" name="upName" className="form-control font-15" placeholder="ইউনিয়ন এর নাম" defaultValue={info.upName} />
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label font-15">ইউনিয়ন নং</label>
                                        <input type="text" name="upNumber" className="form-control font-15" placeholder="ইউনিয়ন নং" defaultValue={info.upNumber} />
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label font-15">স্মারক নং</label>
                                        <input type="text" name="sarokNumber" className="form-control font-15" placeholder="স্মারক নং" defaultValue={info.sarokNumber} />
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label font-15">মোট জনসংখ্যা</label>
                                                <input type="text" name="people" className="form-control font-15" placeholder="মোট জনসংখ্যা" defaultValue={info.people} />
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label font-15">মোট ভোটার</label>
                                                <input type="text" name="totalVotar" className="form-control font-15" placeholder="মোট ভোটার" defaultValue={info.totalVotar} />
                                            </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label font-15">ডাকঘর</label>
                                            <input type="text" name="postOffice" className="form-control font-15" placeholder="ডাকঘর" defaultValue={info.postOffice} />
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label font-15">উপজেলা</label>
                                            <input type="text" name="upzila" className="form-control font-15" placeholder="উপজেলা" defaultValue={info.upzila} />
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label font-15">জেলা</label>
                                            <input type="text" name="jella" className="form-control font-15" placeholder="জেলা" defaultValue={info.jella} />
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label font-15">মোট ওয়ার্ড</label>
                                            <input type="text" name="totalWord" className="form-control font-15" placeholder="মোট ওয়ার্ড" defaultValue={info.totalWord} />
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label font-15">মোট গ্রাম</label>
                                            <input type="text" name="totalVillage" className="form-control font-15" placeholder="মোট গ্রাম" defaultValue={info.totalVillage} />
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label font-15">ইমেইল</label>
                                            <input type="email" name="mail" className="form-control font-15" placeholder="ইমেইল" defaultValue={info.mail} style={{ fontFamily:'arial' }} />
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label font-15">পুরুষ ভোটার</label>
                                                    <input type="text" name="male" className="form-control font-15" placeholder="পুরুষ ভোটার" defaultValue={info.male} />
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label font-15">মহিলা ভোটার</label>
                                                    <input type="text" name="female" className="form-control font-15" placeholder="মহিলা ভোটার" defaultValue={info.female} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                
                                <button type='submit' className='btn btn-danger text-light'>Update Data</button>
                                  
                                
                            </form>
                  

                            
                        
                   
                    </div>
                </div>
            </div>
            
        </div>
    );
};

export default UPInfo;