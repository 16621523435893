import React, { useEffect, useState, useMemo } from 'react';
import { TableHeader, Pagination, Search } from "../DataTable/";
import { Link } from 'react-router-dom';
import Nav from '../Nav/Nav';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from '../../contexts/useAuth';

const VotarData = () => {
    const { userData } = useAuth();
    const [comments, setComments] = useState([]);
    const [dataCount, setCounter] = useState('500');
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [data, setData] = useState();
    const [wordData, setWordData] = useState();
    const [villageData, setVillageData] = useState();
    const [words, setWords] = useState([]);
    const [village, setVillage] = useState([]);

    const successNotify = () =>toast.success('Data Inserted Successfully');
    const failNotify = () =>toast.error('Data Inserted Successfully');
    const successDeleteNotify = () =>toast.success('Data Deleted Successfully');

    //------------------DataTable-------------------------------------------------
    const ITEMS_PER_PAGE = dataCount;

    const upId = userData.ID;
    const sl = comments.length + 1;

    const headers = [
        { name: "ক্রমিক", field: "id", sortable: true },
        { name: "ভোটার সিরিয়াল", field: "votarSerial", sortable: true },
        { name: "ভোটারের নাম", field: "votarName", sortable: true },
        { name: "পিতার নাম", field: "fatherName", sortable: true },
        { name: "মাতার নাম", field: "motherName", sortable: true },
        { name: "ওয়ার্ড", field: "wordName", sortable: true },
        { name: "গ্রাম", field: "village", sortable: true },
        { name: "অপশন", field: "", sortable: false },
    ];
    useEffect(() => {
        getData();
    });
  
    function getData(){
        fetch(`https://api-union.intelligentsoftech.com/votardataAll/${upId}`)
        //fetch(`http://localhost:5000/votar/${upId}`)
                .then(response => response.json())
                .then(json => {
                    setComments(json);
        });
    }
    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    // comment.votar_number.toLowerCase().includes(search.toLowerCase()) ||
                    comment.votarSerial.toLowerCase().includes(search.toLowerCase()) ||
                    comment.votarName.toLowerCase().includes(search.toLowerCase()) ||
                    comment.fatherName.toLowerCase().includes(search.toLowerCase()) ||
                    comment.motherName.toLowerCase().includes(search.toLowerCase()) ||
                    comment.wordName.toLowerCase().includes(search.toLowerCase()) ||
                    comment.village.toLowerCase().includes(search.toLowerCase()) 
            );
        }
        setTotalItems(computedComments.length);
        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }
        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting, ITEMS_PER_PAGE]);
//------------------DataTable--------------------------------------------------------

    const villageHandleOnBlur = e =>{
        const field = e.target.name;
        const value = e.target.value;
        const newInfo = {...villageData};
        newInfo[field] = value;
        setVillageData(newInfo);
    }

    const handleVillageDataSubmit = e =>{
        const newVillageData = {
            upCode: upId,
            ...villageData,
        }
        fetch('https://api-union.intelligentsoftech.com/upVillageName', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newVillageData)
        })
        .then(res=>res.json())
        .then(data=>{
            if(data.insertedId){
                e.target.reset();
                successNotify();
                getVillageData();
            }else{
                failNotify();
            }
        })
        e.preventDefault();
    }

    const wordHandleOnBlur = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newInfo = {...wordData};
        newInfo[field] = value;
        setWordData(newInfo);
    }

    const handleWordDataSubmit = e =>{
        const newWordData = {
            upCode: upId,
            ...wordData,
        }
        fetch('https://api-union.intelligentsoftech.com/upWordName', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(newWordData)
        })
        .then(res=>res.json())
        .then(data=>{
            if(data.insertedId){
                e.target.reset();
                successNotify();
                getWordData();
            }else{
                failNotify();
            }
        })
        e.preventDefault();
    }

    const handleOnBlur = e =>{
        const field = e.target.name;
        const value = e.target.value;
        const newInfo = {...data};
        newInfo[field] = value;
        setData(newInfo);
    }
    
    const handleVotarDataSubmit = e =>{
        const votarData = {
            upCode: upId,
            sl: sl,
            ...data,
        }
        fetch('https://api-union.intelligentsoftech.com/votardata', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(votarData)
        })
        .then(res=>res.json())
        .then(data=>{
            if(data.insertedId){
                e.target.reset();
                successNotify();
                getData();
            }else{
                failNotify();
            }
        })
        e.preventDefault();
    }
    
    //votar delete
    const deleteHandle = id => {
        const proceed = window.confirm('Are you sure to Delete?');
        if(proceed){
          const url = `https://api-union.intelligentsoftech.com/votar/${id}`
          fetch(url, {
            method: 'DELETE'
          })
          .then(res=>res.json())
          .then(data=>{
            if(data.deletedCount>0){
                successDeleteNotify();
              const remaining = comments.filter(votar=>votar._id!==id);
                setComments(remaining);
            }
          })
        }
    }
    //word delete
    const deleteWordHandle = id => {
        const proceed = window.confirm('Are you sure to Delete?');
        if(proceed){
          const url = `https://api-union.intelligentsoftech.com/wordName/${id}`
          fetch(url, {
            method: 'DELETE'
          })
          .then(res=>res.json())
          .then(data=>{
            if(data.deletedCount>0){
                successDeleteNotify();
              const remaining = words.filter(row=>row._id!==id);
              setWords(remaining);
            }
          })
        }
    }
    //village delete
    const deleteVillageHandle = id => {
        const proceed = window.confirm('Are you sure to Delete?');
        if(proceed){
          const url = `https://api-union.intelligentsoftech.com/villageName/${id}`
          fetch(url, {
            method: 'DELETE'
          })
          .then(res=>res.json())
          .then(data=>{
            if(data.deletedCount>0){
                successDeleteNotify();
              const remaining = village.filter(row=>row._id!==id);
              setVillage(remaining);
            }
          })
        }
    }

    //get word name
    useEffect(() => {
        getWordData();
    });

    function getWordData() {
        fetch(`https://api-union.intelligentsoftech.com/upWordName/${upId}`)
        .then(response => response.json())
        .then(json => setWords(json));
    }

    useEffect(() => {
        getVillageData();
    });

    function getVillageData() {
        fetch(`https://api-union.intelligentsoftech.com/upVillageName/${upId}`)
        .then(response => response.json())
        .then(json => setVillage(json));
    }

    let count = 1;

    return (
        <div>
            <Nav></Nav>
            <Toaster
                position='top-right'
            />
            <div className="container shadow mt-3 mb-3">
                <div className="d-grid gap-2 d-md-block">
                    <button className="btn mt-2 btn-success font-14" style={{ marginRight: '4px' }} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal1">ভোটার ডাটা এন্ট্রি</button>
                    <button className="btn mt-2 btn-success font-14" style={{ marginRight: '4px' }} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal2">ওয়ার্ডের নাম এন্ট্রি</button>
                    <button className="btn mt-2 btn-success font-14" style={{ marginRight: '4px' }} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal3">গ্রামের নাম এন্ট্রি</button>
                    <button className="btn mt-2 btn-success font-14" style={{ marginRight: '4px' }} type="button">ভোটার ডাটা ইমপোর্ট (csv)</button>
                </div>
               
                {/*------------- Table Start -------------*/}
                   <div className="mt-2 p-2"> 
                    <div className="row">
                        <div className="col mb-3 col-12 text-center">
                            <div className="row">
                                <div className="col-md-3 mb-2">
                                    <select className="form-select bangla-font" onClick={e => setCounter(e.target.value)} aria-label="Default select example">
                                        <option value="500">প্রতি পেজে ডাটার সংখা</option>
                                        <option value="500">500</option>
                                        <option value="1000">1000</option>
                                        <option value="5000">5000</option>
                                        <option value="10000">10000</option>
                                     
                                    </select>
                                </div>
                                <div className="col-md-6">
                                    <h5>মোট ভোটার ডাটাঃ {comments.length}</h5>
                                </div>
                                <div className="col-md-3 mb-2 d-flex flex-row-reverse">
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">               
                                <table className="table table-striped mb-3" style={{ width:'100%' }}>
                                    <TableHeader
                                        headers={headers}
                                        onSorting={(field, order) =>
                                            setSorting({ field, order })
                                        }
                                    />
                                    <tbody>
                                        {commentsData.map(comment => (
                                            <tr key={comment._id}>
                                                <td>{count++}</td> 
                                                <td>{comment.votarSerial}</td>
                                                <td>{comment.votarName}</td>
                                                <td>{comment.fatherName}</td>
                                                <td>{comment.motherName}</td>
                                                <td>{comment.wordName}</td>
                                                <td>{comment.village}</td>
                                               
                                                <td> 
                                                <div className="dropdown">
                                                    <button className="btn btn-danger btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    অপশন
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        <li><Link className="dropdown-item custom-dropdown" to={`/profile/${comment._id}`}>বিস্তারিত</Link></li>
                                                        <li><Link className="dropdown-item custom-dropdown"  to={`/vataSelect/${comment._id}`}>নির্বাচন করুন ভাতা/সহায়তার জন্য</Link></li>
                                                        <li><Link className="dropdown-item custom-dropdown"  to={`/othrsSelect/${comment._id}`}>নির্বাচন করুন বিভিন্ন তালিকার জন্য</Link></li>
                                                        <li><Link className="dropdown-item custom-dropdown"  to={`/aySelect/${comment._id}`}>নির্বাচন করুন আয় ভিত্তিক তালিকার জন্য</Link></li>
                                                        {
                                                            userData.type === 'Admin' && <li><Link className="dropdown-item custom-dropdown" onClick={()=> deleteHandle(comment._id)} to="#">ডিলেট</Link></li>
                                                        }
                                                         
                                                    </ul>
                                                </div>  
                                            </td> 
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>    
                            <div className="row">
                                <div className="col-md-6">
                                    <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                                <div className="col-md-6 d-flex flex-row-reverse"></div>
                            </div>
                        </div>
                    </div>
                </div>
            {/*------------- Table Start -------------*/}


            </div>

            { /* votar data entry modal  */}
            <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-success text-light">
                            <h5 className="modal-title" id="exampleModalLabel">ভোটার ডাটা এন্ট্রি</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                            <div className="modal-body">
                                <form onSubmit={handleVotarDataSubmit}>
                               
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">ভোটার সিরিয়াল</label>
                                    <input type="text" onBlur={handleOnBlur} className="form-control" name="votarSerial" placeholder="ভোটার সিরিয়াল"/>
                                </div>
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">ভোটারের নাম</label>
                                    <input type="text" onBlur={handleOnBlur} className="form-control" name="votarName" placeholder="ভোটারের নাম" required/>
                                </div>
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">পিতার নাম</label>
                                    <input type="text" onBlur={handleOnBlur} className="form-control" name="fatherName" placeholder="পিতার নাম" required/>
                                </div>
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">মাতার নাম</label>
                                    <input type="text" onBlur={handleOnBlur} className="form-control" name="motherName" placeholder="মাতার নাম"/>
                                </div>
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">পুরুষ / মহিলা</label>
                                    <select className="form-select" onBlur={handleOnBlur} name="gender" aria-label="Default select example" required>
                                        <option value="None">নির্বাচন করুন</option>
                                        <option value="পুরুষ">পুরুষ</option>
                                        <option value="মহিলা">মহিলা</option>
                                    </select>
                                </div>
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">ওয়ার্ড নং</label>
                                    <select className="form-select" onBlur={handleOnBlur} name="wordName" aria-label="Default select example" required>
                                    <option value="None">ওয়ার্ড নির্বাচন করুন</option>
                                    {
                                        words.map((row)=>(
                                            <option value={row.wordName}>{ row.wordName }</option>
                                        ))
                                    }   
                                    </select>
                                </div>
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">গ্রাম</label>
                                    <select className="form-select" onBlur={handleOnBlur} name="village" aria-label="Default select example" required>
                                    <option value="None">গ্রাম নির্বাচন করুন</option> 
                                    {
                                        village.map((row)=>(
                                            <option value={row.villageName}>{ row.villageName }</option>
                                        ))
                                    }   
                                    </select>
                                </div>
                               
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">মোবাইল</label>
                                    <input type="text" onBlur={handleOnBlur} className="form-control" name="mobile" placeholder="মোবাইল"/>
                                </div>
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">NID</label>
                                    <input type="text" onBlur={handleOnBlur} className="form-control" name="nid" placeholder="NID"/>
                                </div>
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">জন্ম তারিখ</label>
                                    <input type="text" onBlur={handleOnBlur} className="form-control" name="dob" placeholder="dd/mm/yy"/>
                                </div>
                                <button type="submit" className="btn btn-block btn-primary mt-2 p-2">সেভ করুন</button>
                                </form>
                            </div>
                            <div className="modal-footer">
                                
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> 
                            </div>
                    </div>
                </div>
            </div>

            { /* word category  data entry modal  */}
            <div className="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-success text-light">
                            <h5 className="modal-title" id="exampleModalLabel">ওয়ার্ডের নাম এন্ট্রি </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleWordDataSubmit}>
                               <div className="mb-1">
                                   <label for="recipient-name" className="col-form-label">ওয়ার্ড নং এবং নাম</label>
                                   <input type="text" onBlur={wordHandleOnBlur} className="form-control" name="wordName" placeholder="ওয়ার্ড নং এবং নাম"/>
                               </div>
                               <button type="submit" className="btn btn-block btn-primary mt-2 p-2">সেভ করুন</button>
                            </form>

                            <table className="table table-success table-striped table-hover mt-3 mb-2">
                                <thead className="table-dark text-light">
                                    <tr>
                                        <th scope="col">ওয়ার্ড নং এবং নাম</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        words.map((row)=>(
                                            <tr>
                                                <td>{ row.wordName }</td>
                                                <td>
                                                 <button onClick={()=> deleteWordHandle(row._id)} className='btn btn-sm btn-danger text-light'>Delete</button></td>
                                            </tr>
                                        ))
                                    }
                                   
                                </tbody>
                            </table>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            { /* village category data entry modal  */}
            <div className="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                        <div className="modal-header bg-success text-light">
                            <h5 className="modal-title" id="exampleModalLabel">গ্রামের নাম এন্ট্রি</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleVillageDataSubmit}>
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">ওয়ার্ড নং এবং নাম</label>
                                    <select className="form-select" onBlur={villageHandleOnBlur} name="wordName" aria-label="Default select example">
                                    {
                                        words.map((row)=>(
                                            <option value={row.wordName}>{ row.wordName }</option>
                                        ))
                                    }   
                                    </select>
                                </div>
                                <div className="mb-1">
                                    <label for="recipient-name" className="col-form-label">গ্রামের নাম</label>
                                    <input type="text" onBlur={villageHandleOnBlur} className="form-control" name="villageName" placeholder="গ্রামের নাম"/>
                                </div>
                                <button type="submit" className="btn btn-block btn-primary mt-2 p-2">সেভ করুন</button>
                            </form>

                            <table className="table table-success table-striped table-hover mt-3 mb-2">
                                <thead className="table-dark text-light">
                                    <tr>
                                        <th scope="col">গ্রামের নাম</th>
                                        <th>ওয়ার্ড নং এবং নাম</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        village.map((row)=>(
                                            <tr>
                                                <td>{ row.villageName }</td>
                                                <td>{ row.wordName }</td>
                                                <td>
                                                 <button onClick={()=> deleteVillageHandle(row._id)} className='btn btn-sm btn-danger text-light'>Delete</button></td>
                                            </tr>
                                        ))
                                    }
                                   
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default VotarData;