import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../contexts/useAuth';

const Nav = () => {
    const { user, userData, logout } = useAuth();
    const [words, setWords] = useState([]);
    const upId = userData.ID;

    //get word name
    useEffect(() => {
        getWordData();
    });
    
    function getWordData() {
        fetch(`https://api-union.intelligentsoftech.com/upWordName/${upId}`)
        .then(response => response.json())
        .then(json => setWords(json));
    }

    return (

        <nav className="navbar navbar-expand-lg navbar-light bg-dark text-light shadow">   
            <div className="container">
                    <button className="navbar-toggler bg-light" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                <Link to="/" className="nav-link text-light font-15 nav-hover nav-hover">হোম</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/votar-data" className="nav-link text-light font-15 nav-hover">ভোটার তথ্য</Link>
                            </li>
                            <div className="dropdown">                             
                                <Link to="#" className="nav-link dropdown-toggle text-light font-15 nav-hover"  id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">সেবাসমূহ</Link>
                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li><Link className="dropdown-item" to="/seba">চারিত্রিক সনদ</Link></li>
                                    <li><Link className="dropdown-item" to="/prottyon-potro">প্রত্যয়ন পত্র</Link></li>
                                    <li><Link className="dropdown-item" to="/oyarish-sonod">ওয়ারিশন সনদ</Link></li>
                                    <li><Link className="dropdown-item" to="/marriage-sonod">বৈবাহিক সনদ</Link></li>
                                    <li><Link className="dropdown-item" to="/ay-sonod">আয়ের সনদ</Link></li>
                                </ul>
                            </div>
                            <li className="nav-item">
                                <Link to="/vata" className="nav-link text-light font-15 nav-hover">ভাতা/সহায়তা তালিকা</Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link text-light font-15 nav-hover" to="/others">বিভিন্ন তালিকা</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/aytalika" className="nav-link text-light font-15 nav-hover">আয় ভিত্তিক তালিকা</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/prokolpo" className="nav-link text-light font-15 nav-hover">প্রকল্পসমূহ</Link>
                            </li>
                            <li className="nav-item">
                                <Link to="/treadlicense" className="nav-link text-light font-15 nav-hover">ট্রেড লাইসেন্স</Link>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle text-light font-15 nav-hover" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    ইউনিয়ন
                                </Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {
                                        words.map((row)=>(
                                            <li key={row._id}><Link className="dropdown-item font-15" to={`/word/${row.wordName}`}>{row.wordName}</Link></li>
                                        ))
                                    }
                                    

                                </ul>
                            </li>
                            <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle text-light font-15 nav-hover" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    সেটিংস
                                </Link>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    {/* <li><Link className="dropdown-item font-15" to="#">সেটিংস</Link></li> */}
                                    <li><Link className="dropdown-item font-15" to="/up-info">ইউনিয়ন তথ্য</Link></li>
                                    <li><Link className="dropdown-item font-15" to="/chairman">চেয়ারম্যানের তথ্য</Link></li>
                                    <li><Link className="dropdown-item font-15" to="/member">মেম্বারের তথ্য</Link></li>
                                    <li><Link className="dropdown-item font-15" to="/office-staff">অফিস স্টাফের তথ্য</Link></li>
                                    <li><Link className="dropdown-item font-15" to="/gram-police">গ্রাম পুলিশের তথ্য</Link></li>
                                    <li><Link className="dropdown-item font-15" to="/logo">ফটো গ্যালারী</Link></li>
                                    <li><Link className="dropdown-item font-15" to="/others-data">অন্যান্য তথ্য</Link></li>
                                </ul>
                               
                            </li>
                            {
                                user?.email ?
                               <>
                                <li className="nav-item dropdown">
                                <Link className="nav-link dropdown-toggle text-light font-15 nav-hover" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    একাউন্ট
                                </Link>
                                    <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li><Link className="dropdown-item font-15" to="/account">Account</Link></li>
                                        {
                                            userData.type === 'Developer' &&
                                            <li><Link className="dropdown-item font-15" to="/regRHTTGhSGOUGUhuishds">রেজিস্ট্রেশন</Link></li>
                                        }                             
                                        <li><Link className="dropdown-item font-15" to="/account-settings">Account Settings</Link></li>
                                        <li onClick={logout}><Link className="dropdown-item font-15" to="#">লগ আউট</Link></li>
                                        
                                    </ul>
                                </li>
                               
                                </>
                                :
                                <li className="nav-item">
                                    <Link to="/login" className="nav-link text-light font-15">লগইন</Link>
                                </li>
                            }
                            
                           
                        </ul>
                
                    </div>
                </div>
        </nav>

      
    );
};

export default Nav;