import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../contexts/useAuth';
    
const PrintMarriageSonodSign = () => {
        const { profileId }  = useParams();
        const { userData } = useAuth();
        const [ profile, setProfile] = useState({});
        const [data, setData] = useState([]);
    
        const id = userData.ID;
    
        useEffect(() =>{
            fetch(`https://api-union.intelligentsoftech.com/marriageDataSingle/${profileId}`)
            .then(res=>res.json())
            .then(data=>setProfile(data));
        }, [profileId])
    
        useEffect(() => {
            getData();
        });
    
        function getData() {
            fetch(`https://api-union.intelligentsoftech.com/upinfo2/${id}`)
            .then(response => response.json())
            .then(json => setData(json));
        }
                  
        return (
            <div>
    
                <div className="print-area2" onClick={()=> window.print() }>
                <div className="mb-4 p-2" style={{  }}>
                <div className="">
                {
                    data.map((row)=>(  
                        <>
                            <div className="row" style={{ borderBottom: '10px solid #EB3742', backgroundColor: '#FFF3E3' }}>
                                <div className="col-sm-12">
                                    <div className="" style={{ }}>
                                        <div style={{ color: '#3A3462', fontSize: '35px', padding: '0px' }}>
                                            <img src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`} style={{ width: '90px', marginLeft:'20px', marginTop: '10px' }} alt="" />
                                            <b>&nbsp; {row.upFullName}</b>
                                        </div>
                                        <div className='text-center' style={{ color: '#3A3462', fontSize: '25px', marginTop:'-30px' }}><b>ডাকঘরঃ {row.postOffice}, উপজেলাঃ {row.upzila}, জেলাঃ {row.jella} ।</b></div>
                                        <h4 className='text-center mb-2 p-1' style={{ border: '2px solid black', borderRadius: '20px', width: '380px', color: 'black', backgroundColor: '', margin: 'auto' }}><b>চেয়ারম্যানঃ {row.chairmanName} </b></h4>
                                        <h5 className='text-center' style={{ fontSize: '23px', fontFamily: 'Arial' }}><b>E-mail: {row.mail}</b></h5>
                                    </div>
                                </div>
                            </div>
                            
                            <br/>
    
                            <div className="demo-wrap2">
                                    <img
                                        className="demo-bg2"
                                        src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`}
                                        alt=""
                                    />
                                    <div className="demo-content2" style={{  }}>
                                        <div className="row font-20">
                                            <div className="col-sm-8">
                                                <p>&nbsp; &nbsp; স্মারক নং - <b> {row.sarokNumber}  </b></p>
                                            </div>
                                            <div className="col-sm-4">
                                                <p>তারিখঃ<span className='border-print'>&nbsp; &nbsp; <b>{ profile.entryDate } খ্রি.</b> &nbsp; </span></p>
                                            </div>
                                            <h2 className='text-center mt-5' style={{ marginTop: '50px' }}><b>{ profile.sutro }</b></h2>
                                            
                                            <div className="col-sm-12">
                                                <p className="p-5 mt-3" style={{ textAlign: 'justify', marginLeft: '50px', marginRight: '50px' }}>
                                                &nbsp; &nbsp; &nbsp; &nbsp; { profile.details }
                                            
                                                </p> 
                                            </div>
                    
                                        </div>
                                    </div>
                                    
                                   <br/>
                                    <div className="row">
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-4"></div>
                                        <div className="col-sm-5 text-center">
                                        <img src={`https://api-union.intelligentsoftech.com/upload/${row.chairmanSign}`} style={{ width: '90px', marginLeft:'20px' }} alt="" />
                                            <div className="">{row.chairmanName}</div>
                                            <div className="" style={{ marginTop: '-6px' }}>চেয়ারম্যান</div>
                                            <div className="" style={{ marginTop: '-6px' }}>{row.upName}</div>
                                            <div className="" style={{ marginTop: '-6px' }}>{row.upzila}, {row.jella}</div>
                                        </div>
                                    </div>
                                
                            
                        </div>
                        </>
                    ))
                }
                </div>
                </div>
                </div>
            </div>
        );
    };
    
export default PrintMarriageSonodSign;