import React, { useState, useEffect } from 'react';
import useAuth from '../../contexts/useAuth';

const HomeCard = () => {
    const { userData } = useAuth();
    const [ words, setWords] = useState([]);
    const [ village, setVillage] = useState([]);
    const [ votar, setVotar] = useState([]);
    const [ character, setCharacter ] = useState([]);
    const [ prottyon, setProttoyon] = useState([]);
    const [ warish, setWarish] = useState([]);
    const [ marriage, setMarriage] = useState([]);
    const [ ay, setAy] = useState([]);
    const [ trade, setTrade] = useState([]);
    const [ vata, setVata] = useState([]);
    const [ others, setOthers] = useState([]);
    const [ prokolpo, setProkolpo] = useState([]);
    //const [ info, setInfo ] = useState({});
    const [ male, setMale ] = useState([]);
    const [ female, setFeMale ] = useState([]);
    
    const upId = userData.ID;

    //console.log(info);
 
    // useEffect(() =>{
    //     getData();
    // },[info]);

    // function getData(){
    //     fetch(`https://api-union.intelligentsoftech.com/upinfo/${userData.ID}`)
    //     .then(response => response.json())
    //     .then(json => setInfo(json));
    // }

    const maleVotar = 'পুরুষ';
    const femaleVotar = 'মহিলা';

        //get prokolpo
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/votarType/${maleVotar}/${upId}`)
            .then(response => response.json())
            .then(json => setMale(json));
        },[upId]);

        //get prokolpo
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/votarType/${femaleVotar}/${upId}`)
            .then(response => response.json())
            .then(json => setFeMale(json));
        },[upId]);

        //get prokolpo
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/prokolpoDataAll/${upId}`)
            .then(response => response.json())
            .then(json => setProkolpo(json));
        },[upId]);

        //get character
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/othersDataAll/${upId}`)
            .then(response => response.json())
            .then(json => setOthers(json));
        },[upId]);

        //get character
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/vataData/${upId}`)
            .then(response => response.json())
            .then(json => setVata(json));
        },[upId]);

        //get character
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/tradeDataAll/${upId}`)
            .then(response => response.json())
            .then(json => setTrade(json));
        },[upId]);

        //get character
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/ayDataAll/${upId}`)
            .then(response => response.json())
            .then(json => setAy(json));
        },[upId]);

        //get character
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/marriageDataAll/${upId}`)
            .then(response => response.json())
            .then(json => setMarriage(json));
        },[upId]);

        //get character
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/warishDataAll/${upId}`)
            .then(response => response.json())
            .then(json => setWarish(json));
        },[upId]);

        //get character
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/prottyonDataAll/${upId}`)
            .then(response => response.json())
            .then(json => setProttoyon(json));
        },[upId]);

        //get character
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/sebaDataAll/${upId}`)
            .then(response => response.json())
            .then(json => setCharacter(json));
        },[upId]);

        //get word name
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/upWordName/${upId}`)
            .then(response => response.json())
            .then(json => setWords(json));
        },[upId]);

        //get village name
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/upVillageName/${upId}`)
            .then(response => response.json())
            .then(json => setVillage(json));
        },[upId]);

        //get votar
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/votardataAll/${upId}`)
            .then(response => response.json())
            .then(json => setVotar(json));
        },[upId]);

    return (
        <div>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট ওয়ার্ড </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{words.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট গ্রাম </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{village.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট ভোটার </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{votar.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট পুরুষ ভোটার </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{male.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট মহিলা ভোটার </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{female.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট ভাতা/সহায়তা প্রদান </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{vata.length}</h3>
                            </div>
                        </div>
                    </div>

                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট বিভিন্ন তালিকা </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{others.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট আয় ভিত্তিক তালিকা </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{ay.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট প্রকল্পসমূহ </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{prokolpo.length}</h3>
                            </div>
                        </div>
                    </div>
                   
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট ট্রেডলাইসেন্স প্রদান </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{trade.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট চারিত্রিক সনদ প্রদান </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{ character.length }</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header text-center bg-dark text-light font-16"> মোট প্রত্যয়ন পত্র প্রদান </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{prottyon.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header bg-dark text-light font-16"> মোট ওয়ারিশন সনদ  প্রদান</div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{warish.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header bg-dark text-light font-16"> মোট বৈবাহিক সনদ প্রদান </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{marriage.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header bg-dark text-light font-16"> মোট আয়ের সনদ প্রদান </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>{ay.length}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-3">
                        <div className="card shadow mb-3 card-border">
                            <div className="card-header bg-dark text-light font-16"> মোট ইউজার একাউন্ট </div>
                            <div className="card-body card-body-style">
                                <h3 className='text-center'>1</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomeCard;