import React from 'react';
import Nav from '../Nav/Nav';
import useAuth from '../../contexts/useAuth';
    
const Account = () => {
        const {user, userData} = useAuth();
    
        return (
            <div>
                <Nav/>
               
                <div className="container mt-3 mb-3">
                    <div className="row">
                        <div className="col-sm-3"></div>
                        <div className="col-sm-6">
                            <div className="card shadow">
                                <div className="card-header bg-primary text-light font-16">Account Information</div>
                                    <div className="card-body">                    
                                    <table className="table table-striped">
                                        <tbody>
                                            <tr>
                                                <th>User Type</th>
                                                <td>{userData.type}</td>
                                            </tr>
                                            <tr>
                                                <th>Email</th>
                                                <td style={{ fontFamily: 'arial' }}>{user.email}</td>
                                            </tr>
                                            <tr>
                                                <th>Post Code</th>
                                                <td>{userData.ID}</td>
                                            </tr>
                                        </tbody>
                                        </table>
                            
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-3"></div>
                    </div>
                </div>
                
            </div>
        );
    };
    
export default Account;