import React from 'react';
import Footer from '../Footer/Footer';
import HomeCard from '../HomeCard/HomeCard';
import Nav from '../Nav/Nav';

const Home = () => {
    return (
        <div style={{ backgroundColor: '#525252'}}>
            <Nav></Nav>
            <HomeCard></HomeCard>
            <Footer></Footer>
        </div>
    );
};

export default Home;