import React, { useEffect, useState, useMemo } from 'react';
import Nav from '../Nav/Nav';
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import useAuth from '../../contexts/useAuth';
import { TableHeader, Pagination, Search } from "../DataTable/";
    
const AyTalika = () => {
    const { userData } = useAuth();
    const [categoryData, setCategoryData] = useState();
    const [category, setCategory] = useState([]);
    const [viewData, setViewData] = useState([]);
    
    //------------------main File-------------------------------------------------  
    const [comments, setComments] = useState([]);
    const [dataCount, setCounter] = useState('50');
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    
        const upId = userData.ID;
    
        const successNotify = () =>toast.success('Data Inserted Successfully');
        const failNotify = () =>toast.error('Data Inserted Successfully');
        const successDeleteNotify = () =>toast.success('Data Deleted Successfully');
    
               
    //------------------DataTable-------------------------------------------------
    const ITEMS_PER_PAGE = dataCount;
    
    const headers = [
        { name: "ক্রমিক", field: "", sortable: false },
        { name: "ক্যাটাগরি", field: "category", sortable: true },
        { name: "গ্রহীতার নাম", field: "votarName", sortable: true },
        { name: "পিতার নাম", field: "fatherName", sortable: true },
        { name: "গ্রাম", field: "village", sortable: false },
        { name: "এন্ট্রি তারিখ", field: "entryDate", sortable: false },
        { name: "অপশন", field: "", sortable: false },
    ];
    
    useEffect(() => {
        getData();
    });
    
    function getData(){
        fetch(`https://api-union.intelligentsoftech.com/ayDataAll/${upId}`)
                .then(response => response.json())
                .then(json => {
                    setComments(json);
        });
    }
    
    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.category.toLowerCase().includes(search.toLowerCase()) ||
                    comment.votarName.toLowerCase().includes(search.toLowerCase()) ||
                    comment.fatherName.toLowerCase().includes(search.toLowerCase())
                    
            );
        }
        setTotalItems(computedComments.length);
        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }
        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting, ITEMS_PER_PAGE]);
    //------------------DataTable--------------------------------------------------------
    
        const categoryHandleOnBlur = e =>{
            const field = e.target.name;
            const value = e.target.value;
            const newInfo = {...categoryData};
            newInfo[field] = value;
            setCategoryData(newInfo);
        }
    
        const handleCategoryDataSubmit = e =>{
            const newCategoryData = {
                upCode: upId,
                ...categoryData,
            }
            fetch('https://api-union.intelligentsoftech.com/ayCategory', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(newCategoryData)
            })
            .then(res=>res.json())
            .then(data=>{
                if(data.insertedId){
                    e.target.reset();
                    successNotify();
                    getCategoryData();
                }else{
                    failNotify();
                }
            })
            e.preventDefault();
        }
    
        //village delete
        const deleteCategoryHandle = id => {
            const proceed = window.confirm('Are you sure to Delete?');
            if(proceed){
                const url = `https://api-union.intelligentsoftech.com/ayCategoryName/${id}`
              fetch(url, {
                method: 'DELETE'
              })
              .then(res=>res.json())
              .then(data=>{
                if(data.deletedCount>0){
                    successDeleteNotify();
                  const remaining = category.filter(row=>row._id!==id);
                    setCategory(remaining);
                }
              })
            }
        }
    
        //vata delete
        const deleteVataHandle = id => {
            const proceed = window.confirm('Are you sure to Delete?');
            if(proceed){
                const url = `https://api-union.intelligentsoftech.com/ayData/${id}`
              fetch(url, {
                method: 'DELETE'
              })
              .then(res=>res.json())
              .then(data=>{
                if(data.deletedCount>0){
                    successDeleteNotify();
                    getData();
                }
              })
            }
        }
    
        //get vata category data
        useEffect(() => {
            getCategoryData();
        });
        
        function getCategoryData() {
            fetch(`https://api-union.intelligentsoftech.com/ayCategoryData/${upId}`)
            .then(response => response.json())
            .then(json => setCategory(json));
        }
    
        const viewHandle = e =>{
            setViewData(e);
        }
    
        let count = 1;
    
    
        return (
            <div>
             
                    <Nav></Nav>
                    <Toaster
                        position='top-right'
                    />
                    <div className="container shadow mt-3 mb-3">
                        <div className="d-grid gap-2 d-md-block">
                           <Link to="/votar-data"><button className="btn mt-2 btn-success font-15" style={{ marginRight: '4px' }} type="button">আয় ভিত্তিক তালিকা তৈরি করুন</button></Link>
                            <button className="btn mt-2 btn-success font-15" style={{ marginRight: '4px' }} type="button" data-bs-toggle="modal" data-bs-target="#exampleModal"> ক্যাটাগরি এন্ট্রি</button>
                        </div> 
    
                       
    
        
                    {/*------------- Table Start -------------*/}
                    <div className="mt-2 p-2"> 
                        <div className="row">
                            <div className="col mb-3 col-12 text-center">
                                <div className="row">
                                    <div className="col-md-3 mb-2">
                                        <select className="form-select bangla-font" onClick={e => setCounter(e.target.value)} aria-label="Default select example">
                                            <option value="50">প্রতি পেজে ডাটার সংখা</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
                                    <div className="col-md-6">
                                        <h5>মোট আয় ভিত্তিক ডাটাঃ {comments.length}</h5>
                                    </div>
                                    <div className="col-md-3 mb-2 d-flex flex-row-reverse">
                                        <Search
                                            onSearch={value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="table-responsive">               
                                    <table className="table table-striped mb-3" style={{ width:'100%' }}>
                                        <TableHeader
                                            headers={headers}
                                            onSorting={(field, order) =>
                                                setSorting({ field, order })
                                            }
                                        />
                                        <tbody>
                                            {commentsData.map(comment => (
                                                <tr key={comment._id}>
                                                    <td>{count++}</td>
                                                    <td>{comment.category}</td>
                                                    <td>{comment.votarName}</td>
                                                    <td>{comment.fatherName}</td>
                                                    <td>{comment.village}</td>
                                                    <td>{comment.entryDate}</td>
                                                    <td> 
                                                        <div className="dropdown">
                                                            <button className="btn btn-danger btn-sm btn-sm dropdown-toggle font-15" type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                                অপশন
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                                                <li><Link className="dropdown-item" onClick={()=> viewHandle(comment)} to="#" data-bs-toggle="modal" data-bs-target="#staticBackdrop">তালিকা বিস্তারিত</Link></li>
                                                                {
                                                                    userData.type === 'Admin' && <li><Link className="dropdown-item" onClick={()=> deleteVataHandle(comment._id)} to="#">ডিলেট</Link></li>
                                                                }
                                                                
                                                            </ul>
                                                        </div>
                                                    </td> 
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>    
                                <div className="row">
                                    <div className="col-md-6">
                                        <Pagination
                                            total={totalItems}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    </div>
                                    <div className="col-md-6 d-flex flex-row-reverse"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                {/*------------- Table Start -------------*/}
                        
    
                    </div>
    
    
                    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-scrollable">
                            <div className="modal-content">
                            <div className="modal-header bg-success text-light">
                                <h5 className="modal-title" id="exampleModalLabel">আয় ভিত্তিক ক্যাটাগরি এন্ট্রি</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={handleCategoryDataSubmit}>
                                   <div className="mb-1">
                                       <label for="recipient-name" className="col-form-label">ক্যাটাগরি</label>
                                       <input type="text" onBlur={categoryHandleOnBlur} className="form-control" name="categoryName" placeholder="ক্যাটাগরি"/>
                                   </div>
                                   <button type="submit" className="btn btn-block btn-primary mt-2 p-2">সেভ করুন</button>
                                </form>
    
                                <table className="table table-success table-striped table-hover mt-3 mb-2">
                                    <thead className="table-dark text-light">
                                        <tr>
                                            <th scope="col">ক্যাটাগরি</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            category.map((row)=>(
                                                <tr>
                                                    <td>{ row.categoryName }</td>
                                                    <td>
                                                         <button onClick={()=> deleteCategoryHandle(row._id)} className='btn btn-sm btn-danger text-light'>Delete</button>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                       
                                    </tbody>
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            </div>
                            </div>
                        </div>
                    </div>
    
    
          
                    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                            <div className="modal-header bg-danger text-light">
                                <h5 className="modal-title font-18" id="staticBackdropLabel">আয় ভিত্তিক তালিকা বিস্তারিত</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <table className="table table-striped">
                                
                                    <tbody>    
                                        <tr>
                                            <td>ক্যাটাগরি </td>
                                            <td>{viewData.category}</td>
                                        </tr>  
                                        <tr>
                                            <td>নাম</td>
                                            <td>{viewData.votarName}</td>
                                        </tr>  
                                        <tr>
                                            <td>পিতার নাম</td>
                                            <td>{viewData.fatherName}</td>
                                        </tr> 
                                        <tr>
                                            <td>মাতার নাম</td>
                                            <td>{viewData.motherName}</td>
                                        </tr> 
                                        <tr>
                                            <td>গ্রাম</td>
                                            <td>{viewData.village}</td>
                                        </tr> 
                                        <tr>
                                            <td>মোবাইল</td>
                                            <td>{viewData.mobile}</td>
                                        </tr>
                                        <tr>
                                            <td>এন আই ডি</td>
                                            <td>{viewData.nid}</td>
                                        </tr>
                                        <tr>
                                            <td>মেয়াদ</td>
                                            <td>{viewData.expiryDate}</td>
                                        </tr>
                                        <tr>
                                            <td>এন্ট্রি তারিখ</td>
                                            <td>{viewData.entryDate}</td>
                                        </tr> 
                                        <tr>
                                            <td>চেয়ারম্যানের নাম</td>
                                            <td>{viewData.chairmanName}</td>
                                        </tr>
                                                                       
                                    </tbody>        
                                </table>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
    
                            </div>
                            </div>
                        </div>
                    </div>
    
    
    
                
            </div>
        );
    };
    
export default AyTalika;