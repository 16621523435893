import React, { useEffect, useState } from 'react';
import useAuth from '../../contexts/useAuth';

const PrintProttyonPotroDownload = () => {
    const { userData } = useAuth();
    const [ data, setInfo ] = useState([]);

    const id = userData.ID;

    useEffect(() =>{
        getData();
    });

    function getData(){
        fetch(`https://api-union.intelligentsoftech.com/upinfo2/${id}`)
        .then(response => response.json())
        .then(json => setInfo(json));
    }
              
    return (
        <div>

            <div className="print-area2" onClick={()=> window.print() }>
            <div className="mb-4 p-2" style={{  }}>
            <div className="">

            {
                data.map((row)=>(  
                    <>
                        <div className="row" style={{ borderBottom: '10px solid #EB3742', backgroundColor: '#FFF3E3' }}>
                            <div className="col-sm-12">
                                <div className="" style={{ }}>
                                    <div style={{ color: '#3A3462', fontSize: '35px', padding: '0px' }}>
                                        <img src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`} style={{ width: '90px', marginLeft:'20px', marginTop: '10px' }} alt="" />
                                        <b>&nbsp; {row.upFullName}</b>
                                    </div>
                                    <div className='text-center' style={{ color: '#3A3462', fontSize: '25px', marginTop:'-30px' }}><b>ডাকঘরঃ {row.postOffice} , উপজেলাঃ {row.upzila}, জেলাঃ {row.jella} ।</b></div>
                                    <h4 className='text-center mb-2 p-1' style={{ border: '2px solid black', borderRadius: '20px', width: '380px', color: 'black', backgroundColor: '', margin: 'auto' }}><b>চেয়ারম্যানঃ {row.chairmanName}</b></h4>
                                    <h5 className='text-center' style={{ fontSize: '23px', fontFamily: 'Arial' }}><b>E-mail: {row.mail} </b></h5>
                                </div>
                            </div>
                        </div>
                        
                        <br/>

                            <div className="demo-wrap2">
                                <img
                                    className="demo-bg2"
                                    src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`}
                                    alt=""
                                />
                                <div className="demo-content2" style={{ height: '750px' }}>
                                    <div className="row font-20">
                                        <div className="col-sm-8">
                                            <p>&nbsp; &nbsp; স্মারক নং - <b> {row.sarokNumber} </b></p>
                                        </div>
                                        <div className="col-sm-4">
                                            <p>তারিখঃ<span className='border-print'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; </span></p>
                                        </div>
                                        
                                        <div className="col-sm-12">
                                        <p className="p-5 mt-3" style={{ textAlign: 'justify', marginLeft: '50px', marginRight: '50px' }}>
                                       
                                        </p>
                                        
                                        </div>
                
                                    </div>
                                </div>
                                
                               <br/> <br/> 
                                <div className="row">
                                    <div className="col-sm-3"></div>
                                    <div className="col-sm-4"></div>
                                    <div className="col-sm-5 text-center">
                                        <div className="">{row.chairmanName}</div>
                                        <div className="" style={{ marginTop: '-6px' }}>চেয়ারম্যান</div>
                                        <div className="" style={{ marginTop: '-6px' }}>{row.upName}</div>
                                        <div className="" style={{ marginTop: '-6px' }}>{row.upzila}, {row.jella}</div>
                                    </div>
                                </div>

                            </div>
                        
                    </>
                   
                ))
            }
            
            </div>
            </div>
            </div>
        </div>
    );
};

export default PrintProttyonPotroDownload;