import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../contexts/useAuth';
    
const PrintTrade = () => {
        const { userData } = useAuth();
        const { profileId }  = useParams();
        const [ profile, setProfile] = useState({});
        const [ data, setData] = useState([]);
    
        const id = userData.ID;
    
        useEffect(() =>{
            fetch(`https://api-union.intelligentsoftech.com/trade/${profileId}`)
            .then(res=>res.json())
            .then(data=>setProfile(data));
        }, [profileId])
    
        useEffect(() => {
            fetch(`https://api-union.intelligentsoftech.com/upinfo2/${id}`)
            .then(response => response.json())
            .then(json => setData(json));
        },[id]);     
       
    
        return (
            <div>
    
                <div className="print-area" onClick={()=> window.print() }>
                <div className="mt-2 mb-4 p-2 border-outside" style={{  }}>
                <div className="">
                {
                    data.map((row)=>(  
                        <div key={row._id}>
                            <div className="row">
                                <div className="col-sm-2">
                                    <img src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`} style={{ width: '70px', marginLeft:'20px' }} alt="" />
                                </div>
                                <div className="col-sm-8">
                                    <h5 className='text-center'>গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</h5>
                                    <h4 className='text-center' style={{ marginTop: '-5px' }}>(স্থানীয় সরকার বিভাগ)</h4>
                                </div>
                                <div className="col-sm-2">
                                </div>
                            </div>
                            <div className="text-center" style={{ marginTop: '-10px' }}>
                                <div className="" style={{ color: '#3A3462', fontSize: '40px', padding: '0px' }}><b>{row.upFullName}</b></div>
                                <div className="" style={{ color: '#3A3462', fontSize: '25px', marginTop:'-7px' }}><b>ডাকঘরঃ {row.postOffice}, উপজেলাঃ {row.upzila}, জেলাঃ {row.jella} ।</b></div>
                               
                                <h5 className='text-center' style={{ fontSize: '23px', fontFamily: 'Arial' }}><b>E-mail: {row.mail} </b></h5>
                                <h4 className='text-center mb-2 p-1' style={{ border: '1px solid #9C0003', borderRadius: '20px', width: '380px', color: 'black', backgroundColor: '#ffcc00', margin: 'auto' }}><b>চেয়ারম্যানঃ {row.chairmanName} </b></h4>
                                <h3 className='text-center p-1' style={{ border: '0px solid', width: '400px', color: 'white', backgroundColor: '#0E0A6A', margin: 'auto' }}><b>ট্রেড লাইসেন্স</b></h3>
                            </div>
                            <br/>
    
                            <div className="demo-wrap">
                                    <img
                                        className="demo-bg"
                                        src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`}
                                        alt=""
                                    />
    
                                    <div className="demo-content">
                                    <div className="row font-20">
                                        <div className="col-sm-8">
                                            <p>&nbsp; &nbsp; লাইসেন্স নং <b>{ profile.licenseNumber }</b></p>
                                        </div>
                                        <div className="col-sm-4">
                                            <p>তারিখঃ<span className='border-print'>&nbsp; &nbsp; <b>{ profile.entryDate } খ্রি.</b> &nbsp; </span></p>
                                        </div>

                                        <div className="" style={{ marginLeft: '10px' }}>
                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="">
                                                        <p>ব্যাবসা প্রতিষ্ঠানের নাম</p> 
                                                        <p>মালিকের নাম</p> 
                                                        <p>পিতা/স্বামীর প্রতিষ্ঠানের নাম</p> 
                                                        <p>বর্তমান ঠিকানা</p> 
                                                        <br/><br/>
                                                        <p>স্থায়ী ঠিকানা</p> 
                                                    </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="row">
                                                        <p> : <span className='border-print'> &nbsp; &nbsp; &nbsp; &nbsp; <b>{profile.businessInstituteName}</b> &nbsp; &nbsp; &nbsp; &nbsp; </span> </p> 
                                                        <p> : <span className='border-print'> &nbsp; &nbsp; &nbsp; &nbsp; <b>{profile.ownerName}</b> &nbsp; &nbsp; &nbsp; &nbsp; </span> </p> 
                                                        <p> : <span className='border-print'> &nbsp; &nbsp; &nbsp; &nbsp; <b>{profile.fatherName}</b> &nbsp; &nbsp; &nbsp; &nbsp; </span> </p> 
                                                          
                                                        <div className="col-sm-6">
                                                            <p> : গ্রামঃ <span className='border-print'> &nbsp; &nbsp; <b>{profile.presentVillage}</b> &nbsp; &nbsp; </span></p>
                                                            <p> : উপজেলাঃ <span className='border-print'> &nbsp; &nbsp; <b>{profile.upzila}</b> &nbsp; &nbsp; </span> </p> 

                                                        </div>
                                                        <div className="col-sm-6">
                                                            <p>ডাকঘরঃ <span className='border-print'> &nbsp; &nbsp; <b>{profile.postOffice}</b> &nbsp; &nbsp; </span></p>
                                                            <p>জেলাঃ <span className='border-print'> &nbsp; &nbsp; <b>{profile.jella}</b> &nbsp; &nbsp; </span></p>
                                                        </div>

                                                        <div className="col-sm-6">
                                                            <p> : গ্রামঃ <span className='border-print'> &nbsp; &nbsp; <b>{profile.permanentVillage}</b> &nbsp; &nbsp; </span></p>
                                                            <p> : উপজেলাঃ <span className='border-print'> &nbsp; &nbsp; <b>{profile.permanentUpzila}</b> &nbsp; &nbsp; </span> </p> 
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <p>ডাকঘরঃ <span className='border-print'> &nbsp; &nbsp; <b>{profile.permanentPost}</b> &nbsp; &nbsp; </span></p>
                                                            <p>জেলাঃ <span className='border-print'> &nbsp; &nbsp; <b>{profile.permanentJella}</b> &nbsp; &nbsp; </span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-4">
                                                    <div className="">
                                                        <p>ব্যাবসা কেন্দ্রঃ</p> 
                                                        <p>ব্যাবসার ধরণঃ</p>
                                                        <p>লাইসেন্স ফি টাকাঃ</p> 
                                                    </div>
                                                </div>
                                                <div className="col-sm-8">
                                                    <div className="">
                                                        <p> : <span className='border-print'> &nbsp; &nbsp; &nbsp; &nbsp; <b>{profile.businessCenter}</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span> </p> 
                                                        <p> : <span className='border-print'> &nbsp; &nbsp; &nbsp; &nbsp; <b>{profile.businessType}</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </span> </p> 
                                                        <p> : <span style={{ border: '2px solid black' }}> &nbsp; <b>{profile.licenseFee}</b> &nbsp; </span> &nbsp; &nbsp; (কথায়ঃ) <b>{profile.licenseFeeText}</b> </p> 
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="">
                                                        <p className='text-success font-16'><b>পরিশোধ করায় তাহাকে এই লাইসেন্স প্রদান করা হইলো।</b></p>
                                                        <p className='text-success font-16'><b>লাইসেন্স বলবত থাকার মেয়াদ</b></p>
                                                        <p className='text-success font-16'>
                                                            <span>{profile.currDate} হইতে </span><br/>
                                                            <span>{profile.expDate} পর্যন্ত </span>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 font-16">
                                                    <div className="text-center mt-5">{row.chairmanName}</div>
                                                    <div className="text-center">চেয়ারম্যান</div>
                                                    <div className="text-center">{row.upName}</div>
                                                    <div className="text-center">{row.upzila}, {row.jella}</div>
                                                    <br/><br/><br/><br/>
                                                </div>
                                            </div>
                                        </div>

                         
                                    </div>
    
                                  
                                   
                                  
                                
                            </div>
                        </div>
                        
                        </div>
                    ))
                }
                </div>
                </div>
                </div>
            </div>
        );
    };
    
export default PrintTrade;