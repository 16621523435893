import React, { useEffect, useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import { TableHeader, Pagination, Search } from "../DataTable/";
import Nav from '../Nav/Nav';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from '../../contexts/useAuth';
import OarishModel from './OarishModel';

const OyarishSonod = () => {
    const { userData } = useAuth();
    const [owarish, setOwarisData] = useState([]);
    const [updateData, setUpdateData] = useState([]);
    const [comments, setComments] = useState([]);
    const [data, setData] = useState('50');
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const ITEMS_PER_PAGE = data;
    const sl = comments.length + 1;
    const category = 'ওয়ারিশন সনদ';
    const upId = userData.ID;

    //console.log(data);
    const headers = [
        { name: "ক্রমিক", field: "sl", sortable: true },
        { name: "সেবা গ্রহীতার নাম", field: "name", sortable: true },
        { name: "পিতার নাম", field: "father", sortable: true },
        { name: "মাতার নাম", field: "mother", sortable: true },
        { name: "গ্রাম", field: "village", sortable: true },
        { name: "সেবা প্রদান তারিখ", field: "entryDate", sortable: true },
        { name: "অপশন", field: "", sortable: false },
    ];

    useEffect(() => {
        getData();
    });

    function getData() {
        fetch(`https://api-union.intelligentsoftech.com/warishDataAll/${upId}`)
                .then(response => response.json())
                .then(json => {
                    //hideLoader();
                    setComments(json);
                   // console.log(json);
        });
    }

    const updateHandle = e =>{
        setUpdateData(e);
    }

    const handleOnBlur = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newInfo = {...owarish};
        newInfo[field] = value;
        //console.log(newInfo);
        setOwarisData(newInfo);
    }

    const successNotify = () =>toast.success('Data Inserted Successfully');
    const failNotify = () =>toast.error('Data Inserted Successfully');
    const successDeleteNotify = () =>toast.success('Data Deleted Successfully');

    const handleSebaDataSubmit = e =>{
        const owarishData = {
            ...owarish,
            upCode: upId,
            sl: sl,
            category,
        }
        fetch('https://api-union.intelligentsoftech.com/warishData', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(owarishData)
        })
        .then(res=>res.json())
        .then(data=>{
            //console.log(data);
            if(data.insertedId){
                //setSuccess(true);
                e.target.reset();
                successNotify();
                getData();
                //setData('');
                
                //alert('Data Inserted Successfully');
            }else{
                failNotify();
            }
        })
        e.preventDefault();
    }

    const deleteHandle = id => {
        const proceed = window.confirm('Are you sure to Delete?');
        if(proceed){
          const url = `https://api-union.intelligentsoftech.com/warishData/${id}`
          fetch(url, {
            method: 'DELETE'
          })
          .then(res=>res.json())
          .then(data=>{
            if(data.deletedCount>0){
                successDeleteNotify();
              const remaining = comments.filter(row=>row._id!==id);
              setComments(remaining);
            }
          })
        }
    }

    const deleteDemo = () =>{
        alert('Button is disabled for demo only');
    }

    const commentsData = useMemo(() => {
        let computedComments = comments;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.sl.toLowerCase().includes(search.toLowerCase()) ||
                    comment.name.toLowerCase().includes(search.toLowerCase()) ||
                    comment.father.toLowerCase().includes(search.toLowerCase()) ||
                    comment.mother.toLowerCase().includes(search.toLowerCase()) ||
                    comment.village.toLowerCase().includes(search.toLowerCase()) ||
                    comment.entryDate.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting, ITEMS_PER_PAGE]);

    return (
        <div>

            <Nav></Nav>
            <Toaster
                position='top-right'
            />


            <div className="container shadow mt-3 mb-3">
                <div className="d-grid gap-2 d-md-block">
                    <button className="btn mt-2 btn-success font-15" style={{ marginRight: '4px' }} data-bs-toggle="modal" data-bs-target="#exampleModal1" type="button">ওয়ারিশন সনদ প্রদান</button>
                    <Link to='/print-warish-download' target="_blank"><button className="btn mt-2 btn-success font-16" style={{ marginRight: '4px' }} type="button">ডাউনলোড ওয়ারিশন সনদ স্বাক্ষর ছাড়া</button></Link>
                    <Link to='/print-warish-download-sign' target="_blank"><button className="btn mt-2 btn-success font-16" style={{ marginRight: '4px' }} type="button">ডাউনলোড ওয়ারিশন সনদ স্বাক্ষর সহ</button></Link>
                </div> 
           
                <div className="mt-2 p-2"> 
                    <div className="row">
                        <div className="col mb-3 col-12 text-center">
                            <div className="row">

                                <div className="col-md-3 mb-2">
                                    <select className="form-select bangla-font" onClick={e => setData(e.target.value)} aria-label="Default select example">
                                        <option value="50">প্রতি পেজে ডাটার সংখ্যা</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>

                                <div className="col-md-6">
                                <h5>মোট ডাটাঃ {comments.length}</h5>
                                </div>
                      
                                <div className="col-md-3 mb-2 d-flex flex-row-reverse">
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="table-responsive">               
                                <table className="table table-striped mb-3" style={{ width:'100%' }}>
                                    <TableHeader
                                        headers={headers}
                                        onSorting={(field, order) =>
                                            setSorting({ field, order })
                                        }
                                    />
                                    <tbody>
                                        {commentsData.map(comment => (
                                            <tr>
                                                <th scope="row" key={comment.id}>
                                                    {comment.sl}
                                                </th>
                                                <td>{comment.name}</td>
                                                <td>{comment.father}</td>
                                                <td>{comment.mother}</td>
                                                <td>{comment.village}</td>
                                                <td>{comment.entryDate}</td>
                                                <td> 
                                                    <div className="dropdown">
                                                        <button className="btn btn-danger btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        অপশন
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><Link className="dropdown-item custom-dropdown" onClick={()=> updateHandle(comment)} data-bs-toggle="modal" data-bs-target="#staticBackdrop" to="#">আপডেট</Link></li>
                                                            <li><Link className="dropdown-item custom-dropdown" to={`/print-warish/${comment._id}/${ comment.sl }`} target="_black">প্রিন্ট স্বাক্ষর ছাড়া</Link></li>
                                                            <li><Link className="dropdown-item custom-dropdown" to={`/print-warish-sign/${comment._id}/${ comment.sl }`} target="_black">প্রিন্ট স্বাক্ষর সহ</Link></li>                                                      
                                                            <li><Link className="dropdown-item custom-dropdown" to="#" onClick={()=> deleteDemo()} disabled>ডিলেট</Link></li>  
                                                            {/* {
                                                                userData.type === 'Admin' && <li><Link className="dropdown-item custom-dropdown" onClick={()=> deleteHandle(comment._id)} to="#">ডিলেট</Link></li>
                                                            } */}
                                                        </ul>
                                                    </div>  
                                                </td> 
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>    
                            <div className="row">
                                <div className="col-md-6">
                                    <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                                <div className="col-md-6 d-flex flex-row-reverse">
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


  
            <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header bg-danger text-light">
                        <h5 className="modal-title" id="exampleModalLabel">ওয়ারিশন সনদ প্রদান</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">

                        <form onSubmit={handleSebaDataSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">নাম</label>
                                        <input type="text" onBlur={handleOnBlur} name="name" className="form-control" placeholder="নাম" required/>
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">পিতা/স্বামী</label>
                                        <input type="text" onBlur={handleOnBlur} name="father" className="form-control" placeholder="পিতা/স্বামী" required/>
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">মাতা</label>
                                        <input type="text" onBlur={handleOnBlur} name="mother" className="form-control" placeholder="মাতা" required/>
                                    </div>
                                </div>
                                <div className="col-sm-6">         
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">ওয়ার্ড নং</label>
                                        <input type="text" onBlur={handleOnBlur} name="wordNumber" className="form-control" placeholder="ওয়ার্ড নং" required/>
                                    </div>
                                   
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">গ্রাম</label>
                                        <input type="text" onBlur={handleOnBlur} name="village" className="form-control" placeholder="গ্রাম" required/>
                                    </div>

                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">সনদ ক্যটাগরি</label>
                                                <select className="form-control" onBlur={handleOnBlur} name="status">
                                                    <option selected>Select Category</option>
                                                    <option value="মৃত্যুকালে">মৃত</option>
                                                    <option value="">জীবিত</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">সনদ প্রদান তারিখ</label>
                                                <input type="text" onBlur={handleOnBlur} name="entryDate" className="form-control" placeholder="dd/mm/yy"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className='bg-dark text-light'>
                                        <tr>
                                            <th scope="col">ক্রমিক নম্বর</th>
                                            <th scope="col">নাম</th>
                                            <th scope="col">সম্পর্ক</th>
                                            <th scope="col">আনুমানিক বয়স</th>
                                            <th scope="col">মন্তব্য</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="০১" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name1" onBlur={handleOnBlur} className="form-control"  placeholder="নাম"/></td>
                                            <td><input type="text" name="relation1" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age1" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment1" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="০২" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name2" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation2" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age2" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment2" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="০৩" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name3" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation3" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age3" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment3" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="০৪" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name4" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation4" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age4" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment4" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="০৫" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name5" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation5" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age5" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment5" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="০৬" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name6" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation6" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age6" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment6" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="০৭" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name7" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation7" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age7" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment7" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="০৮" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name8" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation8" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age8" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment8" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="০৯" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name9" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation9" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age9" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment9" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="১০" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name10" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation10" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age10" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment10" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="১১" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name11" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation11" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age11" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment11" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="১২" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name12" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation12" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age12" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment12" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="১৩" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name13" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation13" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age13" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment13" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="১৪" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name14" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation14" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age14" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment14" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" onBlur={handleOnBlur} className="form-control" value="১৫" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name15" onBlur={handleOnBlur} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation15" className="form-control" onBlur={handleOnBlur} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age15" className="form-control" onBlur={handleOnBlur} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment15" className="form-control" onBlur={handleOnBlur} placeholder="মন্তব্য"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
              
                            <button type='submit' className='btn btn-danger text-light'>সাবমিট</button>
                        </form>

                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                    <div className="modal-header bg-primary text-light">
                        <h5 className="modal-title" id="staticBackdropLabel">ওয়ারিশন সনদ আপডেট</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <OarishModel dataUpdate={updateData}/>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button> 
                    </div>
                    </div>
                </div>
            </div>


            {/* {loader} */}

        </div>
    );
};

export default OyarishSonod;
