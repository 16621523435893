import React, { useEffect, useState } from 'react';
import useAuth from '../../contexts/useAuth';
    
const PrintWarishSonodDownloadSign = () => {
    const [data, setData] = useState([]);
    const { userData } = useAuth();

    const id = userData.ID;

        useEffect(() => {
            getData();
        });
    
        function getData() {
            fetch(`https://api-union.intelligentsoftech.com/upinfo2/${id}`)
            .then(response => response.json())
            .then(json => setData(json));
        }
                  
        return (
            <div>
    
                <div className="print-area2" onClick={()=> window.print() }>
                <div className="mb-4 p-2" style={{  }}>
                <div className="">
                {
                    data.map((row)=>(  
                        <>
                            <div className="row" style={{ borderBottom: '10px solid #EB3742', backgroundColor: '#FFF3E3' }}>
                                <div className="col-sm-12">
                                    <div className="" style={{ }}>
                                        <div style={{ color: '#3A3462', fontSize: '35px', padding: '0px' }}>
                                            <img src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`} style={{ width: '90px', marginLeft:'20px', marginTop: '10px' }} alt="" />
                                            <b>&nbsp; {row.upFullName}</b>
                                        </div>
                                        <div className='text-center' style={{ color: '#3A3462', fontSize: '25px', marginTop:'-30px' }}><b>ডাকঘরঃ {row.postOffice}, উপজেলাঃ {row.upzila}, জেলাঃ {row.jella} ।</b></div>
                                        <h4 className='text-center mb-2 p-1' style={{ border: '2px solid black', borderRadius: '20px', width: '380px', color: 'black', backgroundColor: '', margin: 'auto' }}><b>চেয়ারম্যানঃ {row.chairmanName} </b></h4>
                                        <h5 className='text-center' style={{ fontSize: '23px', fontFamily: 'Arial' }}><b>E-mail: {row.mail}</b></h5>
                                    </div>
                                </div>
                            </div>
                            
                            <br/>
    
                            <div className="demo-wrap2">
                                    <img
                                        className="demo-bg2"
                                        src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`}
                                        alt=""
                                    />
                                    <div className="demo-content2" style={{ height: '950px' }}>
                                        <div className="row font-20">
                                            <div className="col-sm-8">
                                                <p>&nbsp; &nbsp; ক্রমিক নম্বরঃ </p>
                                            </div>
                                            <div className="col-sm-4">
                                                <p>তারিখঃ<span className='border-print'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; </span></p>
                                            </div>
                                            <div className="col-sm-12 mb-4">
                                                <h2 className='text-center mb-2 p-1' style={{ border: '2px solid black', borderRadius: '20px', width: '280px', color: 'black', backgroundColor: '', margin: 'auto' }}><b>ওয়ারিশ সনদ</b></h2>
                                            </div>
                                        </div>

                                        <div className="row" style={{ marginLeft: '40px', marginRight: '40px' }}>
                                            <div className="col-sm-12">
                                                <p> &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp;  &nbsp; এই মর্মে প্রত্যয়ন করা যাইতেছে যে, <strong className='border-print'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp;  </strong> </p>
                                            </div>
                                            <div className="col-sm-6">   
                                                <p style={{ marginLeft: '25px' }}>পিতা/স্বামীঃ  <strong className='border-print'> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp;  </strong></p>
                                            </div>
                                            <div className="col-sm-6">
                                                <p>গ্রামঃ <strong className='border-print'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong></p>
                                            </div>
                                            <div className="col-sm-12">
                                                <p style={{ marginLeft: '25px' }}>ডাকঘরঃ <strong className='border-print'>&nbsp; &nbsp; <b> {row.postOffice} </b> &nbsp; &nbsp; </strong> উপজেলাঃ <b>{row.upzila}</b>, &nbsp; জেলাঃ <b>{row.jella}</b> । তিনি অত্র ইউনিয়নের <strong className='border-print'> &nbsp; &nbsp; &nbsp; &nbsp; </strong> নং ওয়ার্ডের স্থায়ী বাসিন্দা ছিলেন।  </p>
                                                <p style={{ marginLeft: '25px' }}> মৃত্যুকালে তিনি নিম্নলিখিত ওয়ারিশ/ওয়ারিশগণকে রেখে যান। </p>
                                            </div>
                                        </div>

                                        <div className="" style={{ marginLeft: '80px', marginRight: '80px' }}>
                                            <table style={{ width: '100%' }}>
                                                <tr style={{ textAlign: 'center' }}>
                                                    <th style={{ width: '60px'}}>ক্রমিক নম্বর</th>
                                                    <th style={{ marginLeft: '20px', width: '300px' }}>নাম</th>
                                                    <th>সম্পর্ক</th>
                                                    <th style={{ width: '100px' }}>আনুমানিক বয়স</th>
                                                    <th>মন্তব্য</th>
                                                </tr>
                                                
                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>০১</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>০২</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>০৩</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>০৪</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>০৫</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>০৬</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>০৭</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>০৮</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>০৯</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>

                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>১০</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>১১</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>১২</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>১৩</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>১৪</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>১৫</td>
                                                    <td></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                    <td style={{ textAlign: 'center' }}></td>
                                                </tr> 
                                                    
                    
                                            </table>
                                        </div>
                                    
                                   
                                    <div className="row">
                                        <div className="col-sm-3"></div>
                                        <div className="col-sm-4"></div>
                                        <div className="col-sm-5 text-center">
                                            <img src={`https://api-union.intelligentsoftech.com/upload/${row.chairmanSign}`} style={{ width: '90px', marginLeft:'20px' }} alt="" />
                                            <div className="">{row.chairmanName}</div>
                                            <div className="" style={{ marginTop: '-6px' }}>চেয়ারম্যান</div>
                                            <div className="" style={{ marginTop: '-6px' }}>{row.upName}</div>
                                            <div className="" style={{ marginTop: '-6px' }}>{row.upzila}, {row.jella}</div>
                                        </div>
                                    </div>
                                
                                </div>
                        </div>
                        </>
                    ))
                }
                </div>
                </div>
                </div>
            </div>
        );
    };
    
export default PrintWarishSonodDownloadSign;