import React from 'react';
import Nav from '../Nav/Nav';
import useAuth from '../../contexts/useAuth';

const Settings = () => {
        const {changePassword, resetPassword, isLoading, error, success, success2, user } = useAuth();
        const email = user.email
        const handleOnSubmit = e =>{
            e.preventDefault();
            const newPassword  = e.target.elements.newPassword.value;
            const confirmPassword  = e.target.elements.confirmPassword.value;

            if(newPassword === confirmPassword){
                changePassword(newPassword);
            }else{
                alert('Your password did not match');
            }
        }


        const handleOnForgetClick = e =>{
            e.preventDefault();
            const proceed = window.confirm('Are you sure to Forget Password?');
                if(proceed){
                    resetPassword(email);
                }
        }
    
        return (
            <div>
                <Nav/>
               
                <div className="container mt-3 mb-3">
                    <div className="row">
                        <div className="col-sm-4"></div>
                        <div className="col-sm-4">
                            <div className="card shadow">
                                <div className="card-header bg-primary text-light font-16">Change Password</div>
                                    <div className="card-body">
                                        <form onSubmit={handleOnSubmit}>
                                        {
                                            success && 
                                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                <strong>Password Change Success</strong>
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                            </div>
                                        
                                        }
                                        {
                                            success2 && 
                                            <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                <strong>Reset link send your email</strong>
                                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                            </div>
                                        
                                        }
                                        {
                                            error &&
                                            <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                            <strong>{ error }</strong>
                                            <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                        </div>
                                        }
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">নতুন পাসওয়ার্ড</label>
                                                <input type="password" name="newPassword" className="form-control" placeholder="New Password"  required/>
                                            </div>
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">কনফার্ম পাসওয়ার্ড</label>
                                                <input type="password" name="confirmPassword" className="form-control" placeholder="Confirm Password" required/>
                                            </div>
                                            <div className="mb-3 d-grid gap-2 col-6 mx-auto">
                                                <button type='submit' className='btn btn-primary btn-block text-light'>
                                                {
                                                    isLoading &&  <span className="spinner-border spinner-border-sm" style={{ marginRight: '10px' }} role="status" aria-hidden="true"></span> 
                                                }
                                                    CONTINUE</button>
                                            </div>    
                                        </form>

                                        <hr/>

                                        <div className="mb-2 d-grid gap-2 col-10 mx-auto">
                                                <button type='submit' onClick={handleOnForgetClick} className='btn btn-danger btn-block text-light'>
                                                {
                                                    isLoading &&  <span className="spinner-border spinner-border-sm" style={{ marginRight: '10px' }} role="status" aria-hidden="true"></span> 
                                                }
                                                    FORGET PASSWORD</button>
                                        </div> 
                            
                                    </div>
                            </div>
                        </div>
                        <div className="col-sm-4"></div>
                    </div>
                </div>
                
            </div>
        );
    };
    
export default Settings;