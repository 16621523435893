import React, { useEffect, useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import { TableHeader, Pagination, Search } from "../DataTable/";
import Nav from '../Nav/Nav';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from '../../contexts/useAuth';
    
const TreadLicense = () => {
        const { userData } = useAuth();
        const [comments, setComments] = useState([]);
        const [data, setData] = useState('50');
        const [dataSubmit, setSubmitData] = useState();
        //const [loader, showLoader, hideLoader] = useFullPageLoader();
        const [totalItems, setTotalItems] = useState(0);
        const [currentPage, setCurrentPage] = useState(1);
        const [search, setSearch] = useState("");
        const [sorting, setSorting] = useState({ field: "", order: "" });
        const [info, setInfo] = useState({});
        const [updateData, setUpdateData] = useState([]);
    
        const ITEMS_PER_PAGE = data;

        const category = 'ট্রেড লাইসেন্স';
        const sl = comments.length + 1;
        const licenseN = sl.toString();
        const upId = userData.ID;
        
        //console.log(data);

        useEffect(() =>{
            getData2();
        });
    
        function getData2(){
            fetch(`https://api-union.intelligentsoftech.com/upinfo/${upId}`)
            .then(response => response.json())
            .then(json => setInfo(json));
        }
            
        const headers = [
            { name: "লাইসেন্স নাম্বার", field: "licenseNumber", sortable: true },
            { name: "ব্যাবসা প্রতিষ্ঠানের নাম", field: "businessInstituteName", sortable: true },
            { name: "মালিকের নাম", field: "ownerName", sortable: true },
            { name: "মোবাইল নাম্বার", field: "mobile", sortable: true },
            { name: "গ্রাম", field: "presentVillage", sortable: true },
            { name: "প্রদান তারিখ", field: "entryDate", sortable: true },
            { name: "অপশন", field: "", sortable: false },
        ];
    
        useEffect(() => {
            getData();
        });
    
        function getData() {
            fetch(`https://api-union.intelligentsoftech.com/tradeDataAll/${upId}`)
                    .then(response => response.json())
                    .then(json => {
                        //hideLoader();
                        setComments(json);
                       // console.log(json);
            });
        }
    
        const handleOnBlur = e => {
            const field = e.target.name;
            const value = e.target.value;
            const newInfo = {...dataSubmit};
            newInfo[field] = value;
            //console.log(newInfo);
            setSubmitData(newInfo);
        }

    
        const successNotify = () =>toast.success('Data Updated Successfully');
        const failNotify = () =>toast.error('Data Updated Fail');
        const successDeleteNotify = () =>toast.success('Data Deleted Successfully');
    
        //https://aqueous-cliffs-22636.herokuapp.com
        
        const handleTradeLicenseDataSubmit = e =>{
            const sonodData = {
                ...dataSubmit,
                licenseNumber: licenseN,
                upCode: upId,
                postOffice: info.postOffice,
                upzila: info.upzila,
                jella: info.jella,
                category,
            }
            fetch('https://api-union.intelligentsoftech.com/tradeData', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(sonodData)
            })
            .then(res=>res.json())
            .then(data=>{
                //console.log(data);
                if(data.insertedId){
                    //setSuccess(true);
                    e.target.reset();
                    successNotify();
                    getData();
                    //alert('Data Inserted Successfully');
                }else{
                    failNotify();
                }
            })
            e.preventDefault();
        }
    
        const updateHandle = e =>{
            setUpdateData(e);
        }

    
        const handleTradeLicenseDataUpdate = e =>{
            e.preventDefault();
            const id             = e.target.elements.id.value;
            const mobile         = e.target.elements.mobile.value;
            const permanentJella = e.target.elements.permanentJella.value;
            const permanentPost  = e.target.elements.permanentPost.value;
            const permanentUpzila  = e.target.elements.permanentUpzila.value;
            const permanentVillage = e.target.elements.permanentVillage.value;
            const presentVillage   = e.target.elements.presentVillage.value;
            const currDate         = e.target.elements.currDate.value;
            const expDate          = e.target.elements.expDate.value;
            const licenseFee       = e.target.elements.licenseFee.value;
            const licenseFeeText   = e.target.elements.licenseFeeText.value;
            const businessType     = e.target.elements.businessType.value;
            const businessCenter   = e.target.elements.businessCenter.value;
            const fatherName       = e.target.elements.fatherName.value;
            const ownerName        = e.target.elements.ownerName.value;
            const businessInstituteName = e.target.elements.businessInstituteName.value;
            const entryDate        = e.target.elements.entryDate.value;
    
            const infoData = {
                id,
                mobile,
                permanentJella,
                permanentPost,
                permanentUpzila,
                permanentVillage,
                presentVillage,
                licenseFee,
                licenseFeeText,
                businessType,
                businessCenter,
                fatherName,
                ownerName,
                businessInstituteName,
                currDate,
                expDate,
                entryDate,
            }
    
            fetch('https://api-union.intelligentsoftech.com/upTradeData', {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(infoData)
            })
            .then(res=>res.json())
            .then(data=>{
                if(data.acknowledged){
                    //e.target.reset();
                    successNotify();
                    getData();
                   
                }else{
                    failNotify();
                }
            })
        }
    
        const deleteHandle = id => {
            const proceed = window.confirm('Are you sure to Delete?');
            if(proceed){
              const url = `https://api-union.intelligentsoftech.com/trade/${id}`
              fetch(url, {
                method: 'DELETE'
              })
              .then(res=>res.json())
              .then(data=>{
                if(data.deletedCount>0){
                    successDeleteNotify();
                  const remaining = comments.filter(row=>row._id!==id);
                  setComments(remaining);
                }
              })
            }
        }
    
    
        const commentsData = useMemo(() => {
            let computedComments = comments;
    
            if (search) {
                computedComments = computedComments.filter(
                    comment => 
                        comment.businessInstituteName.toLowerCase().includes(search.toLowerCase()) ||
                        comment.ownerName.toLowerCase().includes(search.toLowerCase()) ||
                        comment.presentVillage.toLowerCase().includes(search.toLowerCase()) ||
                        comment.mobile.toLowerCase().includes(search.toLowerCase()) ||
                        comment.entryDate.toLowerCase().includes(search.toLowerCase())
                );
            }
    
            setTotalItems(computedComments.length);
    
            //Sorting comments
            if (sorting.field) {
                const reversed = sorting.order === "asc" ? 1 : -1;
                computedComments = computedComments.sort(
                    (a, b) =>
                        reversed * a[sorting.field].localeCompare(b[sorting.field])
                );
            }
    
            //Current Page slice
            return computedComments.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }, [comments, currentPage, search, sorting, ITEMS_PER_PAGE]);
    
        return (
            <div>
    
                <Nav></Nav>
                <Toaster
                    position='top-right'
                />
    
    
                <div className="container shadow mt-3 mb-3">
                    <div className="d-grid gap-2 d-md-block">
                        <button className="btn mt-2 btn-success font-15" style={{ marginRight: '4px' }} data-bs-toggle="modal" data-bs-target="#exampleModal1" type="button">ট্রেড লাইসেন্স প্রদান</button>
                        <Link to='/trade-download' target="_blank"><button className="btn mt-2 btn-success font-16" style={{ marginRight: '4px' }} type="button">ডাউনলোড ট্রেড লাইসেন্স</button></Link>
                    </div> 

            
                    <div className="mt-2 p-2"> 
                        <div className="row">
                            <div className="col mb-3 col-12 text-center">
                                <div className="row">
    
                                    <div className="col-md-3 mb-2">
                                        <select className="form-select bangla-font" onClick={e => setData(e.target.value)} aria-label="Default select example">
                                            <option value="50">প্রতি পেজে ডাটার সংখ্যা</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
    
                                    <div className="col-md-6">
                                        <h5>মোট ট্রেড লাইসেন্স ডাটাঃ {comments.length}</h5>
                                    </div>
                          
                                    <div className="col-md-3 mb-2 d-flex flex-row-reverse">
                                        <Search
                                            onSearch={value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </div>
                                </div>
    
                                <div className="table-responsive">               
                                    <table className="table table-striped mb-3" style={{ width:'100%' }}>
                                        <TableHeader
                                            headers={headers}
                                            onSorting={(field, order) =>
                                                setSorting({ field, order })
                                            }
                                        />
                                        <tbody>  
                                            {commentsData.map(comment => (
                                                <tr key={comment._id}>
                                                    <td>{comment.licenseNumber}</td>
                                                    <td>{comment.businessInstituteName}</td>
                                                    <td>{comment.ownerName}</td>
                                                    <td>{comment.mobile}</td>
                                                    <td>{comment.presentVillage}</td>
                                                    <td>{comment.entryDate}</td>
                                                    <td> 
                                                        <div className="dropdown">
                                                            <button className="btn btn-danger btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            অপশন
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><Link className="dropdown-item custom-dropdown" onClick={()=> updateHandle(comment)} data-bs-toggle="modal" data-bs-target="#exampleModal" to="#">আপডেট</Link></li>
                                                                <li><Link className="dropdown-item custom-dropdown" to={`/print-trade/${comment._id}/${comment.licenseNumber}`} target="_blank">প্রিন্ট স্বাক্ষর ছাড়া</Link></li>
                                                                <li><Link className="dropdown-item custom-dropdown" to={`/print-trade-sign/${comment._id}/${comment.licenseNumber}`} target="_blank">প্রিন্ট স্বাক্ষর সহ</Link></li>                                                      
                                                                {
                                                                    userData.type === 'Admin' && <li><Link className="dropdown-item custom-dropdown" onClick={()=> deleteHandle(comment._id)} to="#">ডিলেট</Link></li>
                                                                }
                                                            </ul>
                                                        </div>  
                                                    </td> 
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>    
                                <div className="row">
                                    <div className="col-md-6">
                                        <Pagination
                                            total={totalItems}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    </div>
                                    <div className="col-md-6 d-flex flex-row-reverse">
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
                <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-scrollable">
                        <div className="modal-content">
                        <div className="modal-header bg-danger text-light">
                            <h5 className="modal-title" id="exampleModalLabel">ট্রেড লাইসেন্স প্রদান</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleTradeLicenseDataSubmit}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">ব্যাবসা প্রতিষ্ঠানের নাম</label>
                                            <input type="text" onBlur={handleOnBlur} name="businessInstituteName" className="form-control" placeholder="ব্যাবসা প্রতিষ্ঠানের নাম" required/>
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">মালিকের নাম</label>
                                            <input type="text" onBlur={handleOnBlur} name="ownerName" className="form-control" placeholder="মালিকের নাম" required/>
                                        </div>   
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">পিতা/স্বামীর নাম</label>
                                            <input type="text" onBlur={handleOnBlur} name="fatherName" className="form-control" placeholder="পিতা/স্বামীর নাম" required/>
                                        </div>   
                                           
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">ব্যাবসা কেন্দ্র</label>
                                            <input type="text" onBlur={handleOnBlur} name="businessCenter" className="form-control" placeholder="ব্যাবসা কেন্দ্র" required/>
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">ব্যাবসার ধরণ</label>
                                            <input type="text" onBlur={handleOnBlur} name="businessType" className="form-control" placeholder="ব্যাবসার ধরণ" required/>
                                        </div>      
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">লাইসেন্স ফি (টাকায়)</label>
                                                    <input type="text" onBlur={handleOnBlur} name="licenseFee" className="form-control" placeholder="টাকায়" required/>
                                                </div>               
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">লাইসেন্স ফি (কথায়)</label>
                                                    <input type="text" onBlur={handleOnBlur} name="licenseFeeText" className="form-control" placeholder="কথায়" required/>
                                                </div> 
                                            </div>
                                        </div>
                                           
                                    </div>
                                    <div className="col-sm-6">
                                        <label for="exampleFormControlInput1" className="form-label">বর্তমান ঠিকানা</label>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">   
                                                    <input type="text" onBlur={handleOnBlur} name="presentVillage" className="form-control" placeholder="গ্রাম" required/>
                                                </div>       
                                                <div className="mb-3">   
                                                    <input type="text" onBlur={handleOnBlur} className="form-control" defaultValue={info.upzila} placeholder="উপজেলা" disabled/>
                                                </div>              
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">   
                                                    <input type="text" onBlur={handleOnBlur} className="form-control" defaultValue={info.postOffice} placeholder="ডাকঘর" disabled/>
                                                </div>
                                                <div className="mb-3">   
                                                    <input type="text" onBlur={handleOnBlur} className="form-control" defaultValue={info.jella} placeholder="জেলা" disabled/>
                                                </div>
                                            </div>
                                        </div>
                                        <label for="exampleFormControlInput1" className="form-label">স্থায়ী ঠিকানা</label>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">   
                                                    <input type="text" onBlur={handleOnBlur} name="permanentVillage" className="form-control" placeholder="গ্রাম" required/>
                                                </div>       
                                                <div className="mb-3">   
                                                    <input type="text" onBlur={handleOnBlur} name="permanentUpzila" className="form-control" placeholder="উপজেলা" required/>
                                                </div>              
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">   
                                                    <input type="text" onBlur={handleOnBlur} name="permanentPost" className="form-control" placeholder="ডাকঘর" required/>
                                                </div>
                                                <div className="mb-3">   
                                                    <input type="text" onBlur={handleOnBlur} name="permanentJella" className="form-control" placeholder="জেলা" required/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">মোবাইল নাম্বার</label>
                                            <input type="number" onBlur={handleOnBlur} name="mobile" className="form-control" placeholder="মোবাইল নাম্বার" required/>
                                        </div> 
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">প্রদান তারিখ</label>
                                                    <input type="date" onBlur={handleOnBlur} name="entryDate" className="form-control" placeholder="" required/>
                                                </div>                      
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">লাইসেন্স এর মেয়াদ</label>
                                                    <input type="text" onBlur={handleOnBlur} name="currDate" className="form-control" placeholder="হইতে" required/>
                                                </div>                    
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">লাইসেন্স এর মেয়াদ</label>
                                                    <input type="text" onBlur={handleOnBlur} name="expDate" className="form-control" placeholder="পর্যন্ত" required/>
                                                </div>                    
                                            </div>
                                        </div>
                                       
                                        <button type='submit' className='btn btn-primary mt-2 text-light'>সাবমিট করুন</button>

                                    </div>

                                </div>

                                
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>
    
    
                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl modal-dialog-scrollable">
                        <div className="modal-content">
                        <div className="modal-header bg-danger text-light">
                            <h5 className="modal-title" id="exampleModalLabel">ট্রেড লাইসেন্স আপডেট করুন</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleTradeLicenseDataUpdate}>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">ব্যাবসা প্রতিষ্ঠানের নাম</label>
                                            <input type="text" name="businessInstituteName" className="form-control" defaultValue={updateData.businessInstituteName} placeholder="ব্যাবসা প্রতিষ্ঠানের নাম" required/>
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">মালিকের নাম</label>
                                            <input type="text" name="ownerName" className="form-control" defaultValue={updateData.ownerName} placeholder="মালিকের নাম" required/>
                                        </div>   
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">পিতা/স্বামীর নাম</label>
                                            <input type="text" name="fatherName" className="form-control" defaultValue={updateData.fatherName} placeholder="পিতা/স্বামীর নাম" required/>
                                        </div>   
                                           
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">ব্যাবসা কেন্দ্র</label>
                                            <input type="text" name="businessCenter" className="form-control" defaultValue={updateData.businessCenter} placeholder="ব্যাবসা কেন্দ্র" required/>
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">ব্যাবসার ধরণ</label>
                                            <input type="text" name="businessType" className="form-control" defaultValue={updateData.businessType} placeholder="ব্যাবসার ধরণ" required/>
                                        </div>      
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">লাইসেন্স ফি (টাকায়)</label>
                                                    <input type="text" name="licenseFee" className="form-control" defaultValue={updateData.licenseFee} placeholder="টাকায়" required/>
                                                </div>               
                                            </div>
                                            <div className="col-sm-8">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">লাইসেন্স ফি (কথায়)</label>
                                                    <input type="text" onBlur={handleOnBlur} name="licenseFeeText" defaultValue={updateData.licenseFeeText} className="form-control" placeholder="কথায়" required/>
                                                </div> 
                                            </div>
                                            
                                        </div>
                                        
                                    </div>
                                    <div className="col-sm-6">
                                        <label for="exampleFormControlInput1" className="form-label">বর্তমান ঠিকানা</label>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">   
                                                    <input type="text" name="presentVillage" className="form-control" defaultValue={updateData.presentVillage} placeholder="গ্রাম" required/>
                                                </div>       
                                                <div className="mb-3">   
                                                    <input type="text" className="form-control" defaultValue={info.upzila} placeholder="উপজেলা" disabled/>
                                                </div>              
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">   
                                                    <input type="text" className="form-control" defaultValue={info.postOffice} placeholder="ডাকঘর" disabled/>
                                                </div>
                                                <div className="mb-3">   
                                                    <input type="text" className="form-control" defaultValue={info.jella} placeholder="জেলা" disabled/>
                                                </div>
                                            </div>
                                        </div>
                                        <label for="exampleFormControlInput1" className="form-label">স্থায়ী ঠিকানা</label>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="mb-3">   
                                                    <input type="text" name="permanentVillage" className="form-control" defaultValue={updateData.permanentVillage} placeholder="গ্রাম" required/>
                                                </div>       
                                                <div className="mb-3">   
                                                    <input type="text" name="permanentUpzila" className="form-control" defaultValue={updateData.permanentUpzila} placeholder="উপজেলা" required/>
                                                </div>              
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="mb-3">   
                                                    <input type="text" name="permanentPost" className="form-control" defaultValue={updateData.permanentPost} placeholder="ডাকঘর" required/>
                                                </div>
                                                <div className="mb-3">   
                                                    <input type="text" name="permanentJella" className="form-control" defaultValue={updateData.permanentJella} placeholder="জেলা" required/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">মোবাইল নাম্বার</label>
                                            <input type="number" name="mobile" className="form-control" defaultValue={updateData.mobile} placeholder="মোবাইল নাম্বার" required/>
                                        </div> 
                                        <div className="row">
                                            <div className="col-sm-4">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">প্রদান তারিখ</label>
                                                    <input type="date" name="entryDate" className="form-control" defaultValue={updateData.entryDate} placeholder="" required/>
                                                </div>
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">লাইসেন্স এর মেয়াদ</label>
                                                    <input type="text" name="currDate" className="form-control" defaultValue={updateData.currDate} placeholder="হইতে" required/>
                                                </div> 
                                            </div>
                                            <div className="col-sm-4">
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">লাইসেন্স এর মেয়াদ</label>
                                                    <input type="text" name="expDate" className="form-control" defaultValue={updateData.expDate} placeholder="পর্যন্ত" required/>
                                                </div> 
                                            </div>
                                        </div>
                                        <input type="hidden" defaultValue={updateData._id} name="id"/>
                                        <button type='submit' className='btn btn-primary mt-2 text-light'>আপডেট করুন</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
    
                        </div>
                        </div>
                    </div>
                </div>
                {/* {loader} */}
    
            </div>
        );
    };
    
export default TreadLicense;