import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Nav from '../Nav/Nav';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from '../../contexts/useAuth';

const AyTalikaSelect = () => {
        const {userData} = useAuth();
        const { profileId }  = useParams();
        const [ category, setCategory ] = useState([]);
        const [ profile, setProfile] = useState({});
        const [ entryData, setEntryData] = useState();
        const [ ay, setAy] = useState([]);
        const [ data, setInfo] = useState({});
    
        const successNotify = () =>toast.success('Data Inserted Successfully');
        const failNotify = () =>toast.error('Data Inserted Successfully');
    
        const onHandleChange = e =>{
            const field = e.target.name;
            const value = e.target.value;
            const newInfo = {...entryData};
            newInfo[field] = value;
            setEntryData(newInfo);
            //console.log(newInfo);
        }
    
        const chairman = data.chairmanName;
        const upId = userData.ID;
    
        useEffect(() =>{
            getData2();
        });
    
        function getData2(){
            fetch(`https://api-union.intelligentsoftech.com/upinfo/${upId}`)
            .then(response => response.json())
            .then(json => setInfo(json));
        }
    
        const handleOnSubmitData = e =>{
            const newEntryData = {
                ...entryData,
                chairmanName: chairman,
                votarId: profile._id,
                votarName: profile.votarName,
                fatherName: profile.fatherName,
                motherName: profile.motherName,
                village: profile.village,
                mobile: profile.mobile,
                nid: profile.nid,
                upCode: upId,
            }
            fetch('https://api-union.intelligentsoftech.com/ayEntry', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(newEntryData)
            })
            .then(res=>res.json())
            .then(data=>{
                if(data.insertedId){
                    e.target.reset();
                    successNotify();
                    setEntryData('');
                    getData();
                }else{
                    failNotify();
                }
            })
            e.preventDefault();
        }
    
        useEffect(() =>{
            fetch(`https://api-union.intelligentsoftech.com/profile/${profileId}`)
            .then(res=>res.json())
            .then(data=>setProfile(data));
        })
    
        useEffect(() => {
            getData();
        });
    
        function getData() {
            fetch(`https://api-union.intelligentsoftech.com/ayDataAll/${upId}`)
            .then(response => response.json())
            .then(json => setAy(json));
        }
    
        //get vat category data
        // useEffect(() => {
        //     getOthersData();
        // },[]);
    
        // function getOthersData(){
        //     fetch(`https://api-union.intelligentsoftech.com/ayDataSingle/${profileId}`)
        //         .then(res=>res.json())
        //         .then(data=>setOthers(data));
        // }
    
        //get vat category data
        useEffect(() => {
            getCategoryData();
        });
    
        function getCategoryData() {
            fetch(`https://api-union.intelligentsoftech.com/ayCategoryData/${upId}`)
            .then(response => response.json())
            .then(json => setCategory(json));
        }
    
        let count2 = 1;
    
        return (
            <div>
            <Nav></Nav>
            <Toaster
                position='top-right'
            />
            <div className="container mt-2 mb-4">
               
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="card shadow">
                                <div className="card-header bg-dark text-light font-18"> ব্যক্তির তথ্য </div>
                                    <div className="card-body">
                                        <div className="mb-3">
                                            <label className="form-label">নাম</label>
                                            <input type="text" className="form-control" value={profile.votarName} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">পিতা</label>
                                            <input type="text" className="form-control" value={profile.fatherName} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">মাতা</label>
                                            <input type="text" className="form-control" value={profile.motherName} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">গ্রাম</label>
                                            <input type="text" className="form-control" value={profile.village} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">মোবাইল</label>
                                            <input type="text" className="form-control" value={profile.mobile} />
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">এন আই ডি</label>
                                            <input type="text" className="form-control" value={profile.nid} />
                                        </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card shadow">
                                <div className="card-header bg-dark text-light font-18"> বিভিন্ন তথ্য </div>
                                <div className="card-body">
                                    <form onSubmit={handleOnSubmitData}>
                                        <div className="mb-3">
                                            <label className="form-label">ক্যটাগরি</label>
                                            <select className="form-select" onBlur={onHandleChange} name="category" aria-label="Default select example" required>
                                            <option value="">ক্যটাগরি নির্বাচন করুন </option>
                                           {
                                               category.map((row)=>(
                                                    <option value={ row.categoryName }>{ row.categoryName }</option>
                                               ))
                                           }
                                           </select>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">এন্ট্রি তারিখ</label>
                                            <input type="date" onBlur={onHandleChange} name="entryDate" className="form-control" required/>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">তালিকার মেয়াদ</label>
                                            <select className="form-select" onBlur={onHandleChange} name="expiryDate" aria-label="Default select example" required>
                                                <option value="">মেয়াদ নির্বাচন করুন </option>
                                                <option value="৬ মাস">৬ মাস</option>
                                                <option value="১ বছর">১ বছর</option>
                                                <option value="২ বছর">২ বছর</option>
                                                <option value="৩ বছর">৩ বছর</option>
                                                <option value="৪ বছর">৪ বছর</option>
                                                <option value="৫ বছর">৫ বছর</option>
                                                <option value="১০ বছর">১০ বছর</option>
                                                <option value="আজীবন">আজীবন</option>
                                                <option value="নাই">নাই</option>
                                            </select>
                                        </div>
                                        <div className="mb-3">
                                        <label className="form-label">অন্যন্য তথ্য (যদি থাকে)</label>
                                            <textarea className="form-control" placeholder="অন্যন্য তথ্য (যদি থাকে)" onBlur={onHandleChange} name="othersInfo" id="floatingTextarea2" style={{height: '83px' }}></textarea>
                                        </div>
                                        <div className="mb-3">
                                            <label className="form-label">চেয়ারম্যানের নাম</label>
                                            <input type="text" className="form-control" defaultValue={chairman} />
                                        </div>
                                        <button type="submit" className='btn btn-danger text-light'>সাবমিট করুন</button>
                                    </form>
                                   
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                              
    
                   
                        <div className="card shadow mt-2">
                            <div className="card-header bg-dark text-light font-18">বিভিন্ন তালিকা</div>
                            <div className="table-responsive">
                                <table className="table table-striped font-16">
                                        <thead>
                                            <tr>
                                                <th scope="col">ক্রমিক</th>
                                                <th scope="col">ক্যাটাগরি</th>
                                                <th scope="col">মেয়াদ</th>
                                                <th scope="col">প্রদান তারিখ</th>
                                                <th scope="col">চেয়ারম্যন</th>
                                                <th scope="col">অন্যান্য তথ্য</th>
                                            </tr>
                                        </thead>
                                    <tbody>
                                        {
                                            ay.map((row)=>(
                                                <tr>
                                                    <th scope="row">{ count2++ }</th>
                                                    <td>{ row.category }</td>
                                                    <td>{ row.expiryDate }</td>
                                                    <td>{ row.entryDate }</td>
                                                    <td>{ row.chairmanName }</td>
                                                    <td>{ row.othersInfo }</td>
                                                </tr>  
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                   
    
                </div>
    
        </div>
        );
    };
    
export default AyTalikaSelect;