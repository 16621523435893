import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from '../../contexts/useAuth';

const PrintCharitrikSonod = () => {
    const { userData } = useAuth();
    const { profileId }  = useParams();
    const { Id }  = useParams();
    const [ profile, setProfile] = useState({});
    const [ data, setData] = useState([]);

    const id = userData.ID;

    useEffect(() =>{
        fetch(`https://api-union.intelligentsoftech.com/seba/${profileId}`)
        .then(res=>res.json())
        .then(data=>setProfile(data));
    }, [profileId])

    useEffect(() => {
        fetch(`https://api-union.intelligentsoftech.com/upinfo2/${id}`)
        .then(response => response.json())
        .then(json => setData(json));
    },[id]);     
   

    return (
        <div>

            <div className="print-area" onClick={()=> window.print() }>
            <div className="mt-2 mb-4 p-2 border-outside" style={{  }}>
            <div className="">
            {
                data.map((row)=>(  
                    <div key={row._id}>
                        <div className="row">
                            <div className="col-sm-2">
                                <img src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`} style={{ width: '70px', marginLeft:'20px' }} alt="" />
                            </div>
                            <div className="col-sm-8">
                                <h5 className='text-center'>গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</h5>
                                <h4 className='text-center' style={{ marginTop: '-5px' }}>(স্থানীয় সরকার বিভাগ)</h4>
                            </div>
                            <div className="col-sm-2">
                            </div>
                        </div>
                        <div className="text-center" style={{ marginTop: '-10px' }}>
                            <div className="" style={{ color: '#3A3462', fontSize: '40px', padding: '0px' }}><b>{row.upFullName}</b></div>
                            <div className="" style={{ color: '#3A3462', fontSize: '25px', marginTop:'-7px' }}><b>ডাকঘরঃ {row.postOffice}, উপজেলাঃ {row.upzila}, জেলাঃ {row.jella} ।</b></div>
                           
                            <h5 className='text-center' style={{ fontSize: '23px', fontFamily: 'Arial' }}><b>E-mail: {row.mail} </b></h5>
                            <h4 className='text-center mb-2 p-1' style={{ border: '1px solid #9C0003', borderRadius: '20px', width: '380px', color: 'black', backgroundColor: '#ffcc00', margin: 'auto' }}><b>চেয়ারম্যানঃ {row.chairmanName} </b></h4>
                            <h3 className='text-center p-1' style={{ border: '0px solid', width: '400px', color: 'white', backgroundColor: '#0E0A6A', margin: 'auto' }}><b>নাগরিকত্ব / চারিত্রিক সনদপত্র</b></h3>
                        </div>
                        <br/>

                        <div className="demo-wrap">
                                <img
                                    className="demo-bg"
                                    src={`https://api-union.intelligentsoftech.com/upload/${row.upLogo}`}
                                    alt=""
                                />

                                <div className="demo-content">
                                <div className="row font-20">
                                    <div className="col-sm-8">
                                        <p>&nbsp; &nbsp; ক্রমিক নং <b>{ Id }</b></p>
                                    </div>
                                    <div className="col-sm-4">
                                        <p>তারিখঃ<span className='border-print'>&nbsp; &nbsp; <b>{ profile.entryDate } খ্রি.</b> &nbsp; </span></p>
                                    </div>
                                    <div className="col-sm-12">
                                        <p> &nbsp; &nbsp;  &nbsp; &nbsp;  &nbsp; &nbsp; এই মর্মে প্রত্যয়ন করা যাইতেছে যে, <strong className='border-print'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; <b>{profile.name}</b>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong> </p>
                                    </div>
                                    <div className="col-sm-6">   
                                        <p style={{ marginLeft: '6px' }}>পিতা/স্বামীঃ  <strong className='border-print'> &nbsp; &nbsp; <b>{profile.father}</b> &nbsp; &nbsp;  </strong></p>
                                        <p style={{ marginLeft: '6px' }}>গ্রামঃ <strong className='border-print'> &nbsp; &nbsp; &nbsp; <b>{profile.village}</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</strong></p>
                                    </div>
                                    <div className="col-sm-6">
                                        <p>মাতাঃ <strong className='border-print'>&nbsp; &nbsp; <b>{profile.mother}</b> &nbsp; &nbsp; </strong></p>
                                        <p>হোল্ডিং নম্বরঃ<strong className='border-print'>&nbsp; &nbsp; &nbsp; <b>{profile.holdingNumber}</b> &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; </strong> </p>
                                    </div>
                                    <div className="col-sm-12">
                                        <p className="p-2">ডাকঘরঃ<span className='border-print'> &nbsp; <b>{row.postOffice}</b> &nbsp; </span> উপজেলাঃ {row.upzila}, &nbsp; জেলাঃ {row.jella} । এনআইডি নং <strong className='border-print' style={{ letterSpacing: '2px' }}> &nbsp; <b>{profile.idNumber}</b>&nbsp; </strong>  </p>
                                        <p className="p-2" style={{ matginTop: '5px', textAlign: 'justify',  lineHeight: '1.8' }} >তিনি ০২ নং ভেন্ডাবাড়ী ইউনিয়নের <strong className='border-print'> &nbsp; &nbsp; <b>{ profile.wordNumber }</b> &nbsp; &nbsp; </strong> নং ওয়ার্ডের স্থায়ী বাসিন্দা ও জন্মসূত্রে বাংলাদেশের নাগরিক। আমি তাহাকে ব্যক্তিগত ভাবে চিনি ও জানি । তাহার নৈতিক চরিত্র ভালো। আমার জানা মতে তিনি কোন প্রকার সমাজ/রাষ্ট্র বিরোধী কার্যকলাপে জড়িত ছিলেন না। তিনি { profile.marridStatus }।  </p>
                                        <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; আমি তাহার জীবনের সর্বাঙ্গীণ সাফল্য কামনা করি।</p>
                                    </div>
                                  
                     
                                </div>

                                <br/><br/>
                               
                                <div className="row">
                                <div className="col-sm-3"></div>
                                <div className="col-sm-4"></div>
                                <div className="col-sm-5 text-center">
                                    <div className="">{row.chairmanName}</div>
                                    <div className="" style={{ marginTop: '-6px' }}>চেয়ারম্যান</div>
                                    <div className="" style={{ marginTop: '-6px' }}>{row.upName}</div>
                                    <div className="" style={{ marginTop: '-6px' }}>{row.upzila}, {row.jella}</div>
                                   
                                    <br/><br/><br/><br/>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    
                    </div>
                ))
            }
            </div>
            </div>
            </div>
        </div>
    );
};

export default PrintCharitrikSonod;