import React, { useEffect, useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import { TableHeader, Pagination, Search } from "../DataTable/";
import Nav from '../Nav/Nav';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from '../../contexts/useAuth';

const Seba = () => {
    const { userData } = useAuth();
    const [comments, setComments] = useState([]);
    const [data, setData] = useState('50');
    const [dataSubmit, setSubmitData] = useState();
    //const [loader, showLoader, hideLoader] = useFullPageLoader();
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const [updateData, setUpdateData] = useState([]);

    const ITEMS_PER_PAGE = data;

    const category = 'চারিত্রিক সনদ';
    const sl = comments.length + 1;
    const upId = userData.ID;

    //console.log(data);
    
    const headers = [
        { name: "ক্রমিক", field: "sl", sortable: false },
        { name: "সেবা গ্রহীতার নাম", field: "name", sortable: true },
        { name: "পিতার নাম", field: "father", sortable: true },
        { name: "মাতার নাম", field: "mother", sortable: true },
        { name: "গ্রাম", field: "village", sortable: true },
        { name: "সেবা প্রদান তারিখ", field: "entryDate", sortable: true },
        { name: "অপশন", field: "", sortable: false },
    ];

    useEffect(() => {
        getData();
    });

    function getData() {
        fetch(`https://api-union.intelligentsoftech.com/sebaDataAll/${upId}`)
                .then(response => response.json())
                .then(json => {
                    //hideLoader();
                    setComments(json);
                   // console.log(json);
        });

    }

    const handleOnBlur = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newInfo = {...dataSubmit};
        newInfo[field] = value;
        //console.log(newInfo);
        setSubmitData(newInfo);
    }

    const successNotify = () =>toast.success('Data Updated Successfully');
    const failNotify = () =>toast.error('Data Updated Fail');
    const successDeleteNotify = () =>toast.success('Data Deleted Successfully');

    //https://aqueous-cliffs-22636.herokuapp.com

    const handleSebaDataSubmit = e =>{
        const serial = e.target.elements.serial.value;
        const sonodData = {
            ...dataSubmit,
            sl: serial,
            upCode: upId,
            category,
        }
        fetch('https://api-union.intelligentsoftech.com/sebaData', {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(sonodData)
        })
        .then(res=>res.json())
        .then(data=>{
            //console.log(data);
            if(data.insertedId){
                //setSuccess(true);
                e.target.reset();
                successNotify();
                getData();
                //alert('Data Inserted Successfully');
            }else{
                failNotify();
            }
        })
        e.preventDefault();
    }

    const updateHandle = e =>{
        setUpdateData(e);
    }

    const handleSebaDataUpdate = e =>{
        e.preventDefault();
        const sl            = e.target.elements.sl.value;
        const id            = e.target.elements.id.value;
        const name          = e.target.elements.name.value;
        const father        = e.target.elements.father.value;
        const mother        = e.target.elements.mother.value;
        const village       = e.target.elements.village.value;
        const holdingNumber = e.target.elements.holdingNumber.value;
        const wordNumber    = e.target.elements.wordNumber.value;
        const idNumber      = e.target.elements.idNumber.value;
        const marridStatus  = e.target.elements.marridStatus.value;
        const entryDate     = e.target.elements.entryDate.value;

        const infoData = {
            sl,
            id,
            name,
            father,
            mother,
            village,
            holdingNumber,
            wordNumber,
            idNumber,
            marridStatus,
            entryDate,
        }

        fetch('https://api-union.intelligentsoftech.com/upCharacterData', {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(infoData)
        })
        .then(res=>res.json())
        .then(data=>{
            if(data.acknowledged){
                //e.target.reset();
                successNotify();
                getData();
               
            }else{
                failNotify();
            }
        })
    }



    const deleteHandle = id => {
        const proceed = window.confirm('Are you sure to Delete?');
        if(proceed){
          const url = `https://api-union.intelligentsoftech.com/seba/${id}`
          fetch(url, {
            method: 'DELETE'
          })
          .then(res=>res.json())
          .then(data=>{
            if(data.deletedCount>0){
                successDeleteNotify();
              const remaining = comments.filter(row=>row._id!==id);
              setComments(remaining);
            }
          })
        }
    }

    const deleteDemo = () =>{
        alert('Button is disabled for demo only');
    }


    const commentsData = useMemo(() => {
        let computedComments = comments;

        if (search) {
            computedComments = computedComments.filter(
                comment =>
                    comment.sl.toLowerCase().includes(search.toLowerCase()) ||
                    comment.name.toLowerCase().includes(search.toLowerCase()) ||
                    comment.father.toLowerCase().includes(search.toLowerCase()) ||
                    comment.mother.toLowerCase().includes(search.toLowerCase()) ||
                    comment.village.toLowerCase().includes(search.toLowerCase()) ||
                    comment.entryDate.toLowerCase().includes(search.toLowerCase())
            );
        }

        setTotalItems(computedComments.length);

        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting, ITEMS_PER_PAGE]);

    return (
        <div>

            <Nav></Nav>
            <Toaster
                position='top-right'
            />


            <div className="container shadow mt-3 mb-3">
                <div className="d-grid gap-2 d-md-block">
                    <button className="btn mt-2 btn-success font-15" style={{ marginRight: '4px' }} data-bs-toggle="modal" data-bs-target="#exampleModal1" type="button">চারিত্রিক সনদ প্রদান</button>
                    <Link to='/character-download' target="_blank"><button className="btn mt-2 btn-success font-16" style={{ marginRight: '4px' }} type="button" >ডাউনলোড চারিত্রিক সনদ</button></Link>
                </div> 

           
                <div className="mt-2 p-2"> 
                    <div className="row">
                        <div className="col mb-3 col-12 text-center">
                            <div className="row">

                                <div className="col-md-3 mb-2">
                                    <select className="form-select bangla-font" onClick={e => setData(e.target.value)} aria-label="Default select example">
                                        <option value="50">প্রতি পেজে ডাটার সংখ্যা</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>

                                <div className="col-md-6">
                                    <h5>মোট ডাটাঃ {comments.length}</h5>
                                </div>
                      
                                <div className="col-md-3 mb-2 d-flex flex-row-reverse">
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="table-responsive">               
                                <table className="table table-striped mb-3" style={{ width:'100%' }}>
                                    <TableHeader
                                        headers={headers}
                                        onSorting={(field, order) =>
                                            setSorting({ field, order })
                                        }
                                    />
                                    <tbody>
                                        {commentsData.map(comment => (
                                            <tr key={comment._id}>
                                                <th>{comment.sl}</th>
                                                <td>{comment.name}</td>
                                                <td>{comment.father}</td>
                                                <td>{comment.mother}</td>
                                                <td>{comment.village}</td>
                                                <td>{comment.entryDate}</td>
                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-danger btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                        অপশন
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><Link className="dropdown-item custom-dropdown" onClick={()=> updateHandle(comment)} data-bs-toggle="modal" data-bs-target="#exampleModal" to="#">আপডেট</Link></li>
                                                            <li><Link className="dropdown-item custom-dropdown" to={`/print-character/${comment._id}/${comment.sl}`} target="_blank">প্রিন্ট স্বাক্ষর ছাড়া</Link></li>
                                                            <li><Link className="dropdown-item custom-dropdown" to={`/print-character-sign/${comment._id}/${comment.sl}`} target="_blank">প্রিন্ট স্বাক্ষর সহ</Link></li>                                                      
                                                            <li><Link className="dropdown-item custom-dropdown" to="#" onClick={()=> deleteDemo()} disabled>ডিলেট</Link></li>  
                                                            {/* {
                                                                userData.type === 'Admin' && <li><Link className="dropdown-item custom-dropdown" onClick={()=> deleteHandle(comment._id)} to="#">ডিলেট</Link></li>
                                                            } */}
                                                        </ul>
                                                    </div>  
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>    
                            <div className="row">
                                <div className="col-md-6">
                                    <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                                <div className="col-md-6 d-flex flex-row-reverse">
                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header bg-danger text-light">
                        <h5 className="modal-title" id="exampleModalLabel">চারিত্রিক সনদ প্রদান</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSebaDataSubmit}>
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">নাম</label>
                                <input type="text" onBlur={handleOnBlur} name="name" className="form-control" placeholder="নাম" required/>
                            </div>
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">পিতা/স্বামী</label>
                                <input type="text" onBlur={handleOnBlur} name="father" className="form-control" placeholder="পিতা/স্বামী" required/>
                            </div>
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">মাতা</label>
                                <input type="text" onBlur={handleOnBlur} name="mother" className="form-control" placeholder="মাতা" required/>
                            </div>
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">ওয়ার্ড নং</label>
                                <input type="text" onBlur={handleOnBlur} name="wordNumber" className="form-control" placeholder="ওয়ার্ড নং" required/>
                            </div>
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">গ্রাম</label>
                                <input type="text" onBlur={handleOnBlur} name="village" className="form-control" placeholder="গ্রাম" required/>
                            </div>
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">হোল্ডিং নম্বর</label>
                                <input type="text" onBlur={handleOnBlur} name="holdingNumber" className="form-control" placeholder="হোল্ডিং নম্বর"/>
                            </div>
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">আইডি নম্বর</label>
                                <input type="text" onBlur={handleOnBlur} name="idNumber" className="form-control" placeholder="আইডি নম্বর"/>
                            </div>
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">বৈবাহিক অবস্থা</label>
                                <select className="form-control" onBlur={handleOnBlur} name="marridStatus">
                                    <option value="বিবাহিত">বিবাহিত</option>
                                    <option value="অবিবাহিত">অবিবাহিত</option>
                                </select>
                            </div> 
                            <div className="mb-3">
                                <label for="exampleFormControlInput1" className="form-label">সনদ প্রদান তারিখ</label>
                                <input type="text" onBlur={handleOnBlur} name="entryDate" className="form-control" placeholder="dd/mm/yy"/>
                            </div>
                             <input type="hidden" name="serial" defaultValue={sl} />                               
                            <button type='submit' className='btn btn-danger text-light'>সাবমিট</button>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
            </div>


            <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header bg-danger text-light">
                        <h5 className="modal-title" id="exampleModalLabel">চারিত্রিক সনদ আপডেট করুন</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSebaDataUpdate}>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">ক্রমিক নম্বর</label>
                                    <input type="text" name="sl" className="form-control" defaultValue={updateData.sl} placeholder="ক্রমিক নম্বর" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">নাম</label>
                                    <input type="text" name="name" className="form-control" defaultValue={updateData.name} placeholder="নাম" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">পিতা/স্বামী</label>
                                    <input type="text" name="father" className="form-control" defaultValue={updateData.father} placeholder="পিতা/স্বামী" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">মাতা</label>
                                    <input type="text" name="mother" className="form-control" defaultValue={updateData.mother} placeholder="মাতা" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">ওয়ার্ড নং</label>
                                    <input type="text" name="wordNumber" className="form-control" defaultValue={updateData.wordNumber} placeholder="ওয়ার্ড নং" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">গ্রাম</label>
                                    <input type="text" name="village" className="form-control" defaultValue={updateData.village} placeholder="গ্রাম" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">হোল্ডিং নম্বর</label>
                                    <input type="text" name="holdingNumber" className="form-control" defaultValue={updateData.holdingNumber} placeholder="হোল্ডিং নম্বর"/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">আইডি নম্বর</label>
                                    <input type="text" name="idNumber" className="form-control" defaultValue={updateData.idNumber} placeholder="আইডি নম্বর"/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">বৈবাহিক অবস্থা</label>
                                    <select className="form-control" name="marridStatus">
                                        <option defaultValue={updateData.marridStatus}>{updateData.marridStatus}</option>
                                        <option value="বিবাহিত">বিবাহিত</option>
                                        <option value="অবিবাহিত">অবিবাহিত</option>
                                    </select>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1"  className="form-label">সনদ প্রদান তারিখ</label>
                                    <input type="text" name="entryDate" defaultValue={updateData.entryDate}  className="form-control" placeholder="dd/mm/yy" required/>
                                </div>
                                <input type="hidden" name="id" defaultValue={updateData._id} />
                                <button type='submit' className='btn btn-danger text-light'>আপডেট</button>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                    </div>
                    </div>
                </div>
            </div>
            {/* {loader} */}

        </div>
    );
};

export default Seba;