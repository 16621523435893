import React from 'react';
import toast from 'react-hot-toast';

const OarishModel = ({dataUpdate}) => {

    const successNotify = () =>toast.success('Data Updated Successfully');
    const failNotify = () =>toast.error('Data Updated Fail');

    const handleSebaDataSubmit = e =>{
        e.preventDefault();
        const sl  = e.target.elements.sl.value;
        const id = e.target.elements.id.value;
        const name = e.target.elements.name.value;
        const father = e.target.elements.father.value;
        const mother = e.target.elements.mother.value;
        const wordNumber = e.target.elements.wordNumber.value;
        const village = e.target.elements.village.value;
        const status = e.target.elements.status.value;
        const entryDate  = e.target.elements.entryDate.value;
        
        const name1 = e.target.elements.name1.value;
        const relation1  = e.target.elements.relation1.value;
        const age1 = e.target.elements.age1.value;
        const comment1 = e.target.elements.comment1.value;
      
        const name2 = e.target.elements.name2.value;
        const relation2  = e.target.elements.relation2.value;
        const age2 = e.target.elements.age2.value;
        const comment2 = e.target.elements.comment2.value;

        const name3= e.target.elements.name3.value;
        const relation3  = e.target.elements.relation3.value;
        const age3 = e.target.elements.age3.value;
        const comment3 = e.target.elements.comment3.value;

        const name4 = e.target.elements.name4.value;
        const relation4 = e.target.elements.relation4.value;
        const age4 = e.target.elements.age4.value;
        const comment4 = e.target.elements.comment4.value;

        const name5 = e.target.elements.name5.value;
        const relation5  = e.target.elements.relation5.value;
        const age5 = e.target.elements.age5.value;
        const comment5 = e.target.elements.comment5.value;

        const name6 = e.target.elements.name6.value;
        const relation6  = e.target.elements.relation6.value;
        const age6 = e.target.elements.age6.value;
        const comment6 = e.target.elements.comment6.value;

        const name7 = e.target.elements.name7.value;
        const relation7  = e.target.elements.relation7.value;
        const age7 = e.target.elements.age7.value;
        const comment7 = e.target.elements.comment7.value;
        
        const name8 = e.target.elements.name8.value;
        const relation8  = e.target.elements.relation8.value;
        const age8 = e.target.elements.age8.value;
        const comment8 = e.target.elements.comment8.value;

        const name9 = e.target.elements.name9.value;
        const relation9  = e.target.elements.relation9.value;
        const age9 = e.target.elements.age9.value;
        const comment9 = e.target.elements.comment9.value;

        const name10 = e.target.elements.name10.value;
        const relation10  = e.target.elements.relation10.value;
        const age10 = e.target.elements.age10.value;
        const comment10 = e.target.elements.comment10.value;

        const name11 = e.target.elements.name11.value;
        const relation11  = e.target.elements.relation11.value;
        const age11 = e.target.elements.age11.value;
        const comment11 = e.target.elements.comment11.value;

        const name12 = e.target.elements.name12.value;
        const relation12  = e.target.elements.relation12.value;
        const age12 = e.target.elements.age12.value;
        const comment12 = e.target.elements.comment12.value;
        
        const name13 = e.target.elements.name13.value;
        const relation13  = e.target.elements.relation13.value;
        const age13 = e.target.elements.age13.value;
        const comment13 = e.target.elements.comment13.value;

        const name14 = e.target.elements.name14.value;
        const relation14  = e.target.elements.relation14.value;
        const age14 = e.target.elements.age14.value;
        const comment14 = e.target.elements.comment14.value;

        const name15 = e.target.elements.name15.value;
        const relation15  = e.target.elements.relation15.value;
        const age15 = e.target.elements.age15.value;
        const comment15 = e.target.elements.comment15.value;
        
        const infoData = {
            sl, id, name, father, mother, wordNumber, village, status, entryDate, name1, relation1, age1, comment1, name2, relation2, age2, comment2, name3, relation3, age3, comment3, name4, relation4, age4, comment4,
            name5, relation5, age5, comment5, name6, relation6, age6, comment6, name7, relation7, age7, comment7, name8, relation8, age8, comment8, name9, relation9, age9, comment9,
            name10, relation10, age10, comment10, name11, relation11, age11, comment11, name12, relation12, age12, comment12, name13, relation13, age13, comment13, name14, relation14, age14, comment14, name15, relation15, age15, comment15,
        }

        fetch('https://api-union.intelligentsoftech.com/warishDataUpdate', {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(infoData)
        })
        .then(res=>res.json())
        .then(data=>{
            if(data.acknowledged){
                successNotify();
            }else{
                failNotify();
            }
        })
    }

    return (
        <div>
           
                <form onSubmit={handleSebaDataSubmit}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">নাম</label>
                                        <input type="text" name="name" defaultValue={dataUpdate.name} className="form-control" placeholder="নাম" required/>
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">পিতা/স্বামী</label>
                                        <input type="text" name="father" defaultValue={dataUpdate.father} className="form-control" placeholder="পিতা/স্বামী" required/>
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">মাতা</label>
                                        <input type="text" name="mother" defaultValue={dataUpdate.mother} className="form-control" placeholder="মাতা" required/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">ওয়ার্ড নং</label>
                                                <input type="text" name="wordNumber" defaultValue={dataUpdate.wordNumber} className="form-control" placeholder="ওয়ার্ড নং" required/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                        <div className="mb-3">
                                            <label for="exampleFormControlInput1" className="form-label">ক্রমিক নম্বর</label>
                                            <input type="text" name="sl" className="form-control" defaultValue={dataUpdate.sl} placeholder="ক্রমিক নম্বর" required/>
                                        </div>
                                        </div>
                                    </div>
                                   
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">গ্রাম</label>
                                        <input type="text" name="village" defaultValue={dataUpdate.village} className="form-control" placeholder="গ্রাম" required/>
                                    </div>
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">সনদ ক্যটাগরি</label>
                                                <select className="form-control" name="status">
                                                    <option value={dataUpdate.status}>{dataUpdate.status}</option>
                                                    <option value="মৃত্যুকালে">মৃত</option>
                                                    <option value="">জীবিত</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">সনদ প্রদান তারিখ</label>
                                                <input type="text" name="entryDate" defaultValue={dataUpdate.entryDate} className="form-control" placeholder="dd/mm/yy"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table">
                                    <thead className='bg-dark text-light'>
                                        <tr>
                                            <th scope="col">ক্রমিক নম্বর</th>
                                            <th scope="col">নাম</th>
                                            <th scope="col">সম্পর্ক</th>
                                            <th scope="col">আনুমানিক বয়স</th>
                                            <th scope="col">মন্তব্য</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="০১" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name1" defaultValue={dataUpdate.name1} className="form-control"  placeholder="নাম"/></td>
                                            <td><input type="text" name="relation1" defaultValue={dataUpdate.relation1} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age1" defaultValue={dataUpdate.age1} className="form-control" placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment1" defaultValue={dataUpdate.comment1} className="form-control" placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="০২" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name2" defaultValue={dataUpdate.name2} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation2" defaultValue={dataUpdate.relation2} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age2" defaultValue={dataUpdate.age2} className="form-control" placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment2" defaultValue={dataUpdate.comment2} className="form-control" placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="০৩" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name3" defaultValue={dataUpdate.name3} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation3" defaultValue={dataUpdate.relation3} className="form-control"   placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age3" defaultValue={dataUpdate.age3} className="form-control"   placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment3" defaultValue={dataUpdate.comment3} className="form-control"   placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="০৪" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name4" defaultValue={dataUpdate.name4} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation4" defaultValue={dataUpdate.relation4} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age4" defaultValue={dataUpdate.age4} className="form-control" placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment4" defaultValue={dataUpdate.comment4} className="form-control"   placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="০৫" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name5" defaultValue={dataUpdate.name5} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation5" defaultValue={dataUpdate.relation5} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age5" defaultValue={dataUpdate.age5} className="form-control" placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment5" defaultValue={dataUpdate.comment5}className="form-control" placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="০৬" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name6" defaultValue={dataUpdate.name6} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation6" defaultValue={dataUpdate.relation6} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age6" defaultValue={dataUpdate.age6} className="form-control" placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment6" defaultValue={dataUpdate.comment6} className="form-control" placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="০৭" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name7" defaultValue={dataUpdate.name7} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation7" defaultValue={dataUpdate.relation7} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age7" defaultValue={dataUpdate.age7} className="form-control"  placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment7" defaultValue={dataUpdate.comment7} className="form-control" placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="০৮" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name8" defaultValue={dataUpdate.name8} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation8" defaultValue={dataUpdate.relation8} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age8" defaultValue={dataUpdate.age8} className="form-control" placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment8" defaultValue={dataUpdate.comment8} className="form-control" placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="০৯" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name9" defaultValue={dataUpdate.name9} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation9" defaultValue={dataUpdate.relation9} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age9" defaultValue={dataUpdate.age9} className="form-control" placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment9" defaultValue={dataUpdate.comment9} className="form-control" placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="১০" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name10"  defaultValue={dataUpdate.name10} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation10" defaultValue={dataUpdate.relation10} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age10" defaultValue={dataUpdate.age10} className="form-control" placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment10" defaultValue={dataUpdate.comment10} className="form-control" placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="১১" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name11" defaultValue={dataUpdate.name11} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation11" defaultValue={dataUpdate.relation11} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age11" defaultValue={dataUpdate.age11} className="form-control" placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment11" className="form-control" defaultValue={dataUpdate.comment11}  placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="১২" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name12" defaultValue={dataUpdate.name12} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation12" className="form-control" defaultValue={dataUpdate.relation12}  placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age12" className="form-control" defaultValue={dataUpdate.age12}  placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment12" className="form-control" defaultValue={dataUpdate.comment12}  placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text" className="form-control" value="১৩" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name13" defaultValue={dataUpdate.name13} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation13" className="form-control" defaultValue={dataUpdate.relation13} placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age13" className="form-control" defaultValue={dataUpdate.age13} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment13" className="form-control" defaultValue={dataUpdate.comment13} placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text"  className="form-control" value="১৪" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name14" defaultValue={dataUpdate.name14} className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation14" defaultValue={dataUpdate.relation14} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age14" className="form-control" defaultValue={dataUpdate.age14} placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment14" defaultValue={dataUpdate.comment14} className="form-control" placeholder="মন্তব্য"/></td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: '100px' }}><input type="text"   className="form-control" value="১৫" placeholder="ক্রমিক"/></th>
                                            <td><input type="text" name="name15" defaultValue={dataUpdate.name15}  className="form-control" placeholder="নাম"/></td>
                                            <td><input type="text" name="relation15" defaultValue={dataUpdate.relation15} className="form-control" placeholder="সম্পর্ক"/></td>
                                            <td><input type="text" name="age15" className="form-control" defaultValue={dataUpdate.age15}  placeholder="আনুমানিক বয়স"/></td>
                                            <td><input type="text" name="comment15" className="form-control" defaultValue={dataUpdate.comment15} placeholder="মন্তব্য"/></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                           
                    <input type="hidden" name='id' value={dataUpdate._id} />
                    <button type='submit' className='btn btn-danger text-light'>আপডেট</button>
                </form>     

        </div>
    );
};

export default OarishModel;