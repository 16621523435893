import React, { useEffect, useState } from "react";
import Nav from '../../Nav/Nav';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from '../../../contexts/useAuth';
    
const Logo = () => {
        const { userData } = useAuth();
        const [image, setImage] = useState('');
        const [logo, setLogo] = useState({});
        const [loading, setLoading] = useState(false);
        
        const successNotify = () =>toast.success('Logo Updated Successfully');
        const failNotify = () =>toast.error('Logo Updated Fail');

        const handleOnSubmit = (e) =>{
            e.preventDefault();
            setLoading(true);
            const type = e.target.elements.type.value;
            const formData = new FormData();
            formData.append('image', image);
            formData.append('type', type);
            formData.append('upCode', userData.ID);
            
            fetch('https://api-union.intelligentsoftech.com/uploadGallery',{
                method: 'PUT',
                body: formData
            })
            .then(res => res.json())
            .then(data=>{
                if(data.acknowledged){
                    successNotify();
                    setLoading(false);
                    e.target.reset();
                }else{
                    failNotify();
                    setLoading(false);
                }
            })
        }

        useEffect(() =>{
            fetch(`https://api-union.intelligentsoftech.com/upGallery/${userData.ID}`)
            .then(res=>res.json())
            .then(data=>setLogo(data));
        })



        return (
            <div>
    
                <Nav></Nav>

                <Toaster
                    position='top-right'
                />

                <div className="container mt-2">
                    <div className="card shadow">
                        <div className="card-header bg-primary text-light font-bangla">ফটো গ্যালারী 
                            { loading && 
                                <div className="spinner-border spinner-border-sm" role="status"></div> 
                            }
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-sm-4">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-success text-light">ইউনিয়ন পরিষদ লোগো</div>
                                            <div className="card-body">
                                                <img src={`https://api-union.intelligentsoftech.com/upload/${logo.upLogo}`} style={{ width: '200px', height: '200px'}} alt="" />
                                                <form onSubmit={handleOnSubmit}>
                                                <div className="form-group">
                                                    <label for="">Logo Upload</label>
                                                    <input type="file" className="form-control" onChange={e => setImage(e.target.files[0])} id="formFile" required/>
                                                </div>
                                                <input type="hidden" name="type" defaultValue="upLogo" />
                                                <button type="submit" className="btn btn-danger mt-3">
                                                    Upload Now</button>
                                                </form>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-success text-light">চেয়ারম্যান এর ফোটো</div>
                                            <div className="card-body">
                                                <img src={`https://api-union.intelligentsoftech.com/upload/${logo.chairmanPhoto}`} style={{ width: '200px', height: '200px'}} alt="" />
                                                <form onSubmit={handleOnSubmit}>
                                                <div className="form-group">
                                                    <label for="">Logo Upload</label>
                                                    <input type="file" className="form-control" onChange={e => setImage(e.target.files[0])} id="formFile" required/>
                                                </div>
                                                <input type="hidden" name="type" defaultValue="chairmanPhoto" />
                                                <button type="submit" className="btn btn-danger mt-3">
                                                    Upload Now</button>
                                                </form>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-success text-light">চেয়ারম্যান এর স্বাক্ষর</div>
                                        <div className="card-body">
                                            <img src={`https://api-union.intelligentsoftech.com/upload/${logo.chairmanSign}`} style={{ width: '200px', height: '200px'}} alt="" />
                                            <form onSubmit={handleOnSubmit}>
                                            <div className="form-group">
                                                <label for="">Logo Upload</label>
                                                <input type="file" className="form-control" onChange={e => setImage(e.target.files[0])} id="formFile" required/>
                                            </div>
                                            <input type="hidden" name="type" defaultValue="chairmanSign" />
                                            <button type="submit" className="btn btn-danger mt-3">
                                                Upload Now</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-3">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-success text-light">সচিব এর ফোটো</div>
                                            <div className="card-body">
                                                <img src={`https://api-union.intelligentsoftech.com/upload/${logo.sochibPhoto}`} style={{ width: '200px', height: '200px'}} alt="" />
                                                <form onSubmit={handleOnSubmit}>
                                                <div className="form-group">
                                                    <label for="">Logo Upload</label>
                                                    <input type="file" className="form-control" onChange={e => setImage(e.target.files[0])} id="formFile" required/>
                                                </div>
                                                <input type="hidden" name="type" defaultValue="sochibPhoto" />
                                                <button type="submit" className="btn btn-danger mt-3">
                                                    Upload Now</button>
                                                </form>
                                            </div>
                                    </div>
                                </div>
                                <div className="col-sm-4 mt-3">
                                    <div className="card shadow-sm">
                                        <div className="card-header bg-success text-light">সচিব এর স্বাক্ষর</div>
                                        <div className="card-body">
                                            <img src={`https://api-union.intelligentsoftech.com/upload/${logo.sochibSign}`} style={{ width: '200px', height: '200px'}} alt="" />
                                            <form onSubmit={handleOnSubmit}>
                                            <div className="form-group">
                                                <label for="">Logo Upload</label>
                                                <input type="file" className="form-control" onChange={e => setImage(e.target.files[0])} id="formFile" required/>
                                            </div>
                                            <input type="hidden" name="type" defaultValue="sochibSign" />
                                            <button type="submit" className="btn btn-danger mt-3">
                                                Upload Now</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
    
            </div>
        );
    };
    
export default Logo;
