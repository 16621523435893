import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './App.css';
import AyTalika from './component/AyTalika/AyTalika';
import Home from './component/Home/Home';
import Login from './component/Login/Login';
import Register from './component/Login/Register';
import Others from './component/Others/Others';
import PrivateRoute from './component/PrivateRoute/PrivateRoute';
import AySonod from './component/Seba/AySonod';
import CharacterDownload from './component/Seba/CharacterDownload';
import MarriageSonod from './component/Seba/MarriageSonod';
import OyarishSonod from './component/Seba/OyarishSonod';
import OthersSelect from './component/Others/OthersSelect';
import PrintAySonod from './component/Seba/PrintAySonod';
import PrintAySonodSign from './component/Seba/PrintAySonodSign';
import PrintCharitrikSonod from './component/Seba/PrintCharitrikSonod';
import PrintCharitrikSonodSign from './component/Seba/PrintCharitrikSonodSign';
import PrintMarriageSonod from './component/Seba/PrintMarriageSonod';
import PrintMarriageSonodSign from './component/Seba/PrintMarriageSonodSign';
import PrintProttyonPotro from './component/Seba/PrintProttyonPotro';
import PrintProttyonPotroDownload from './component/Seba/PrintProttyonPotroDownload';
import PrintProttyonPotroSign from './component/Seba/PrintProttyonPotroSign';
import PrintWarishSonod from './component/Seba/PrintWarishSonod';
import PrintWarishSonodDownload from './component/Seba/PrintWarishSonodDownload';
import PrintWarishSonodDownloadSign from './component/Seba/PrintWarishSonodDownloadSign';
import PrintWarishSonodSign from './component/Seba/PrintWarishSonodSign';
import ProttyonPotro from './component/Seba/ProttyonPotro';
import Seba from './component/Seba/Seba';
import TreadLicense from './component/TreadLicense/TreadLicense';
import Account from './component/UPInfo/Account';
import Chairman from './component/UPInfo/Chairman/Chairman';
import GramPolic from './component/UPInfo/GramPolice/GramPolic';
import Logo from './component/UPInfo/Logo/Logo';
import Member from './component/UPInfo/Member/Member';
import OfficeStaff from './component/UPInfo/OfficeStaff/OfficeStaff';
import OthersInfo from './component/UPInfo/OthersInfo/Others';
import UPInfo from './component/UPInfo/UPInfo';
import Data from './component/Vata/Data';
import Vata from './component/Vata/Vata';
import VataSelect from './component/Vata/VataSelect';
import VotarData from './component/VotatData/VotarData';
import VotarProfile from './component/VotatData/VotarProfile';
import AuthProvider from './contexts/AuthProvider';
import Prokolpo from './component/Prokolpo/Prokolpo';
import AyTalikaSelect from './component/AyTalika/AyTalikaSelect';
import PrintTrade from './component/TreadLicense/PrintTrade';
import PrintTradeSign from './component/TreadLicense/PrintTradeSign';
import Download from './component/TreadLicense/Download';
import Word from './component/VotatData/Word';
import Settings from './component/Account/Settings';
import Forget from './component/Account/Forget';

function App() {
  return (
    <div className="App">
      <AuthProvider>
        <Router>
          <Switch>
            <PrivateRoute exact path="/">
              <Home/>
            </PrivateRoute>
            <PrivateRoute path="/home">
                <Home/>
            </PrivateRoute>
            <PrivateRoute path="/votar-data">
              <VotarData/>
            </PrivateRoute>
            <PrivateRoute path="/seba">
              <Seba/>
            </PrivateRoute>
            <PrivateRoute path="/character-download">
              <CharacterDownload/>
            </PrivateRoute>
            <privateRoute path="/prottyon-potro">
              <ProttyonPotro/>
            </privateRoute>
            <privateRoute path="/oyarish-sonod">
              <OyarishSonod/>
            </privateRoute>
            <privateRoute path="/marriage-sonod">
              <MarriageSonod/>
            </privateRoute>
            <privateRoute path="/ay-sonod">
              <AySonod/>
            </privateRoute>
            <PrivateRoute path="/vata">
              <Vata/>
            </PrivateRoute>
            <PrivateRoute path="/treadlicense">
              <TreadLicense/>
            </PrivateRoute>
            <PrivateRoute path="/aytalika">
              <AyTalika/>
            </PrivateRoute>
            <PrivateRoute path="/profile/:profileId">
                <VotarProfile/>
            </PrivateRoute>
            <PrivateRoute path="/vataSelect/:profileId">
                <VataSelect/>
            </PrivateRoute>           
            <PrivateRoute path="/prokolpo">
                <Prokolpo/>
            </PrivateRoute>
            <PrivateRoute path="/others">
                <Others/>
            </PrivateRoute>
            <PrivateRoute path="/up-info">
                <UPInfo/>
            </PrivateRoute>
            <PrivateRoute path="/print-character/:profileId/:Id">
                <PrintCharitrikSonod/>
            </PrivateRoute>
            <PrivateRoute path="/print-character-sign/:profileId/:Id">
                <PrintCharitrikSonodSign/>
            </PrivateRoute>
            <PrivateRoute path="/print-prottyon/:profileId/:Id">
              <PrintProttyonPotro/>
            </PrivateRoute>
            <PrivateRoute path="/print-prottyon-sign/:profileId/:Id">
              <PrintProttyonPotroSign/>
            </PrivateRoute>  
            <PrivateRoute path="/print-marriage/:profileId/:Id">
              <PrintMarriageSonod/>
            </PrivateRoute>
            <PrivateRoute path="/print-marriage-sign/:profileId/:Id">
              <PrintMarriageSonodSign/>
            </PrivateRoute>
            <PrivateRoute path="/print-ay/:profileId/:Id">
              <PrintAySonod/>
            </PrivateRoute>
            <PrivateRoute path="/print-ay-sign/:profileId/:Id">
              <PrintAySonodSign/>
            </PrivateRoute>
            <PrivateRoute path="/print-prottyon-download">
              <PrintProttyonPotroDownload/>
            </PrivateRoute> 
            <PrivateRoute path="/print-warish/:profileId/:Id">
              <PrintWarishSonod/>
            </PrivateRoute>
            <PrivateRoute path="/print-warish-sign/:profileId/:Id">
              <PrintWarishSonodSign/>
            </PrivateRoute>
            <PrivateRoute path="/print-warish-download">
              <PrintWarishSonodDownload/>
            </PrivateRoute>
            <PrivateRoute path="/print-warish-download-sign">
              <PrintWarishSonodDownloadSign/>
            </PrivateRoute>

            <PrivateRoute path="/othrsSelect/:profileId">
               <OthersSelect/>
            </PrivateRoute>
            
            <PrivateRoute path="/aySelect/:profileId">
               <AyTalikaSelect/>
            </PrivateRoute>
            
            <PrivateRoute path="/print-trade/:profileId">
               <PrintTrade/>
            </PrivateRoute>

            <PrivateRoute path="/print-trade-sign/:profileId">
               <PrintTradeSign/>
            </PrivateRoute>

            <PrivateRoute path="/trade-download">
               <Download/>
            </PrivateRoute>

            <PrivateRoute path="/word/:profileId">
               <Word/>
            </PrivateRoute>

            <PrivateRoute path="/data">
                <Data/>
            </PrivateRoute>

            <PrivateRoute path="/chairman">
                <Chairman/>
            </PrivateRoute>

            <PrivateRoute path="/member">
                <Member/>
            </PrivateRoute>

            <PrivateRoute path="/office-staff">
                <OfficeStaff/>
            </PrivateRoute>

            <PrivateRoute path="/gram-police">
                <GramPolic/>
            </PrivateRoute>

            <PrivateRoute path="/others-data">
                <OthersInfo/>
            </PrivateRoute>

            <PrivateRoute path="/logo">
                <Logo/>
            </PrivateRoute>
            
            <Route path="/login">
              <Login/>
            </Route>

            <Route path="/forget">
              <Forget/>
            </Route>
            
            <PrivateRoute path="/account">
                <Account/>
            </PrivateRoute>

            <PrivateRoute path="/account-settings">
                <Settings/>
            </PrivateRoute>
             
            <PrivateRoute path="/regRHTTGhSGOUGUhuishds">
              <Register/>
            </PrivateRoute>
          </Switch>
        </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
