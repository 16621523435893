import { Link } from 'react-router-dom';
import useAuth from '../../contexts/useAuth';

const Forget = () => {
    const { resetPassword, isLoading, error, success2 } = useAuth();

    const handleOnSubmit = e => {
        e.preventDefault();
        const email  = e.target.elements.email.value;
        const proceed = window.confirm('Are you sure to Forget Password?');
        if(proceed){
            resetPassword(email);
        }
    }


    return (
        <div>
            <div className="container p-3 mt-4 mb-4">
                <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <div className="card shadow">
                            <div className="card-header bg-dark text-light font-22">Forget Password</div>
                            <div className="card-body">
                                <form onSubmit={handleOnSubmit}>
                                {
                                     success2 &&
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        <strong>Reset link send your email</strong>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                   
                                 }
                                 {
                                     error &&
                                      <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                     <strong>{ error }</strong>
                                     <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                 </div>
                                 }
                                
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">Your Email</label>
                                        <input type="text" name="email" className="form-control" placeholder="Enter Your Email" style={{ fontFamily: 'arial'}} required/>
                                    </div>
                                   
                                    <div className="mb-3 d-grid gap-2 col-6 mx-auto">
                                        <button type='submit' className='btn btn-primary btn-block text-light'>
                                        {
                                            isLoading &&  <span className="spinner-border spinner-border-sm" style={{ marginRight: '10px' }} role="status" aria-hidden="true"></span> 
                                        }
                                            CONTINUE</button>
                                    </div>
                                    
                                </form>

                                <div className="mb-3 d-grid gap-2 col-6 mx-auto">
                                    <Link to="/login" style={{ textDecoration: 'none', textAlign: 'center' }}>Login</Link>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4"></div>
                </div>
            </div>
        </div>
    );
};

export default Forget;