import React, { useEffect, useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import { TableHeader, Pagination, Search } from "../DataTable/";
import Nav from '../Nav/Nav';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from '../../contexts/useAuth';
    
const ProttyonPotro = () => {
    const { userData } = useAuth();
    const [updateData, setUpdateData] = useState([]);
    const [comments, setComments] = useState([]);
    const [data, setData] = useState('50');
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    
        const ITEMS_PER_PAGE = data;
        const sl = comments.length + 1;
        const category = 'প্রত্যয়ন পত্র';
        const upId = userData.ID;

        //console.log(data);
        const headers = [
            { name: "ক্রমিক", field: "sl", sortable: true },
            { name: "সেবার নাম", field: "category", sortable: true },
            { name: "সুত্র", field: "sutro", sortable: true },
            { name: "সেবা প্রদান তারিখ", field: "entryDate", sortable: true },
            { name: "অপশন", field: "", sortable: false },
        ];
    
        useEffect(() => {
            getData();
        });
    
        function getData(){
            fetch(`https://api-union.intelligentsoftech.com/prottyonDataAll/${upId}`)
                    .then(response => response.json())
                    .then(json => {
                        //hideLoader();
                        setComments(json);
                       // console.log(json);
            });
        }
    
        const successNotify = () =>toast.success('Data Inserted Successfully');
        const upNotify = () =>toast.success('Data Updated Successfully');
        const failNotify = () =>toast.error('Data Inserted Successfully');
        const successDeleteNotify = () =>toast.success('Data Deleted Successfully');
    
        const handleSebaDataSubmit = e =>{
            e.preventDefault();
            const sutro = e.target.elements.sutro.value;
            const entryDate = e.target.elements.entryDate.value;
            const details = e.target.elements.details.value;
           
            const sebaData = {
                upCode: upId,
                sl: sl,
                category,
                sutro,
                entryDate,
                details,
            }
    
            fetch('https://api-union.intelligentsoftech.com/prottyonData', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(sebaData)
            })
            .then(res=>res.json())
            .then(data=>{
                if(data.insertedId){
                    e.target.reset();
                    successNotify();
                    getData();
                }else{
                    failNotify();
                }
            })
        }
    
        const handleSebaDataUpdate = e =>{
            e.preventDefault();
            const sl        = e.target.elements.sl.value;
            const id        = e.target.elements.id.value;  
            const sutro     = e.target.elements.sutro.value;
            const entryDate = e.target.elements.entryDate.value;
            const details   = e.target.elements.details.value;
    
            const sebaData = {
                sl,
                id,
                sutro,
                entryDate,
                details,
            }
    
            fetch('https://api-union.intelligentsoftech.com/prottyonDataUpdate', {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify(sebaData)
            })
            .then(res=>res.json())
            .then(data=>{
                if(data.acknowledged){
                    upNotify();
                    getData();
                }else{
                    failNotify();
                }
            })
        }
    
        const deleteHandle = id => {
            const proceed = window.confirm('Are you sure to Delete?');
            if(proceed){
              const url = `https://api-union.intelligentsoftech.com/prottyonData/${id}`
              fetch(url, {
                method: 'DELETE'
              })
              .then(res=>res.json())
              .then(data=>{
                if(data.deletedCount>0){
                    successDeleteNotify();
                  const remaining = comments.filter(row=>row._id!==id);
                  setComments(remaining);
                }
              })
            }
        }

        const deleteDemo = () =>{
            alert('Button is disabled for demo only');
        }
    
    
        const commentsData = useMemo(() => {
            let computedComments = comments;
    
            if (search) {
                computedComments = computedComments.filter(
                    comment =>
                        comment.sl.toLowerCase().includes(search.toLowerCase()) ||
                        comment.category.toLowerCase().includes(search.toLowerCase()) ||
                        comment.sutro.toLowerCase().includes(search.toLowerCase()) ||
                        comment.entryDate.toLowerCase().includes(search.toLowerCase())
                );
            }
    
            setTotalItems(computedComments.length);
    
            //Sorting comments
            if (sorting.field) {
                const reversed = sorting.order === "asc" ? 1 : -1;
                computedComments = computedComments.sort(
                    (a, b) =>
                        reversed * a[sorting.field].localeCompare(b[sorting.field])
                );
            }
    
            //Current Page slice
            return computedComments.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        }, [comments, currentPage, search, sorting, ITEMS_PER_PAGE]);
    
        return (
            <div>
    
                <Nav></Nav>
                <Toaster
                    position='top-right'
                />
    
    
                <div className="container shadow mt-3 mb-3">
                    <div className="d-grid gap-2 d-md-block">
                        <button className="btn mt-2 btn-success font-15" style={{ marginRight: '4px' }} data-bs-toggle="modal" data-bs-target="#exampleModal1" type="button">প্রত্যয়ন পত্র প্রদান</button>
                        <Link to='/print-prottyon-download' target="_blank"><button className="btn mt-2 btn-success font-16" style={{ marginRight: '4px' }} type="button">ডাউনলোড প্রত্যয়ন পত্র</button></Link>                  
                    </div> 
               
                    <div className="mt-2 p-2"> 
                        <div className="row">
                            <div className="col mb-3 col-12 text-center">
                                <div className="row">
    
                                    <div className="col-md-3 mb-2">
                                        <select className="form-select bangla-font" onClick={e => setData(e.target.value)} aria-label="Default select example">
                                            <option value="50">প্রতি পেজে ডাটার সংখ্যা</option>
                                            <option value="20">20</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                            <option value="200">200</option>
                                            <option value="500">500</option>
                                        </select>
                                    </div>
    
                                    <div className="col-md-6">
                                    <h5>মোট ডাটাঃ {comments.length}</h5>
                                    </div>
                          
                                    <div className="col-md-3 mb-2 d-flex flex-row-reverse">
                                        <Search
                                            onSearch={value => {
                                                setSearch(value);
                                                setCurrentPage(1);
                                            }}
                                        />
                                    </div>
                                </div>
    
                                <div className="table-responsive">               
                                    <table className="table table-striped mb-3" style={{ width:'100%' }}>
                                        <TableHeader
                                            headers={headers}
                                            onSorting={(field, order) =>
                                                setSorting({ field, order })
                                            }
                                        />
                                        <tbody>
                                            {commentsData.map(comment => (
                                                <tr key={comment._id}>
                                                    <th scope="row">{comment.sl}</th>
                                                    <td>{comment.category}</td>
                                                    <td>{comment.sutro}</td>
                                                    <td>{comment.entryDate}</td>
                                                    <td> 
                                                        <div className="dropdown">
                                                            <button className="btn btn-danger btn-sm dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            অপশন
                                                            </button>
                                                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                <li><Link className="dropdown-item custom-dropdown" onClick={()=> setUpdateData(comment)} data-bs-toggle="modal" data-bs-target="#exampleModal2" to="#">আপডেট</Link></li>
                                                                <li><Link className="dropdown-item custom-dropdown" to={`/print-prottyon/${comment._id}/${ comment.sl }`} target="_black">প্রিন্ট স্বাক্ষর ছাড়া</Link></li>
                                                                <li><Link className="dropdown-item custom-dropdown" to={`/print-prottyon-sign/${comment._id}/${ comment.sl }`} target="_black">প্রিন্ট স্বাক্ষর সহ</Link></li>                                                      
                                                                <li><Link className="dropdown-item custom-dropdown" to="#" onClick={()=> deleteDemo()} disabled>ডিলেট</Link></li>  
                                                                {/* {
                                                                    userData.type === 'Admin' && <li><Link className="dropdown-item custom-dropdown" onClick={()=> deleteHandle(comment._id)} to="#">ডিলেট</Link></li>
                                                                } */}
                                                            </ul>
                                                        </div>  
                                                    </td> 
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>    
                                <div className="row">
                                    <div className="col-md-6">
                                        <Pagination
                                            total={totalItems}
                                            itemsPerPage={ITEMS_PER_PAGE}
                                            currentPage={currentPage}
                                            onPageChange={page => setCurrentPage(page)}
                                        />
                                    </div>
                                    <div className="col-md-6 d-flex flex-row-reverse">
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    
    
                <div className="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable">
                        <div className="modal-content">
                        <div className="modal-header bg-danger text-light">
                            <h5 className="modal-title" id="exampleModalLabel">প্রত্যয়ন পত্র প্রদান</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSebaDataSubmit}>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">প্রত্যয়ন পত্র ক্যাটাগরি</label>
                                    <input type="text" name="sutro" className="form-control" placeholder="প্রত্যয়ন পত্র ক্যাটাগরি" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">প্রত্যয়ন পত্র প্রদান তারিখ</label>
                                    <input type="text" name="entryDate" className="form-control" placeholder="dd/mm/yy"/>
                                </div>
                                <div className="mb-2">
                                    <label for="exampleFormControlInput1" className="form-label">বিস্তারিত বিবরণ</label>
                                    <textarea className="form-control" placeholder="Details" name="details" id="floatingTextarea2" style={{ height: '100px' }}></textarea>
                                    
                                </div>
    
                                <button type='submit' className='btn btn-primary text-light'>সাবমিট করুন</button>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>
    
                <div className="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable">
                        <div className="modal-content">
                        <div className="modal-header bg-danger text-light">
                            <h5 className="modal-title" id="exampleModalLabel">প্রত্যয়ন পত্র আপডেট</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form onSubmit={handleSebaDataUpdate}>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">ক্রমিক নম্বর</label>
                                    <input type="text" name="sl" className="form-control" defaultValue={updateData.sl} placeholder="ক্রমিক নম্বর" required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">প্রত্যয়ন পত্র ক্যাটাগরি</label>
                                    <input type="text" name="sutro" className="form-control" placeholder="প্রত্যয়ন পত্র ক্যাটাগরি" defaultValue={updateData.sutro} required/>
                                </div>
                                <div className="mb-3">
                                    <label for="exampleFormControlInput1" className="form-label">প্রত্যয়ন পত্র প্রদান তারিখ</label>
                                    <input type="text" name="entryDate" className="form-control" defaultValue={updateData.entryDate} placeholder="dd/mm/yy"/>
                                </div>
                                <div className="mb-2">
                                    <label for="exampleFormControlInput1" className="form-label">বিস্তারিত বিবরণ</label>
                                    <textarea className="form-control" placeholder="Details" defaultValue={updateData.details} name="details" id="floatingTextarea2" style={{ height: '100px' }}></textarea>
                                    {/* <div className="editorClassName">
                                        <Editor
                                            editorState={editorState}
                                            onEditorStateChange={setEditorState}
                                           defaultValue={updateData.entryDate}
                                        />
                                    </div> */}
                                </div>
                                <input type="hidden" name="id" defaultValue={updateData._id} />
                                <button type='submit' className='btn btn-primary text-light'>আপডেট করুন</button>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                        </div>
                    </div>
                </div>
    
                {/* {loader} */}
    
            </div>
        );
    };
    
export default ProttyonPotro;