// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBLdQr3VRxz-duaXxdfu2UZaAmpQnu3PpM",
    authDomain: "digitalup-78ec4.firebaseapp.com",
    projectId: "digitalup-78ec4",
    storageBucket: "digitalup-78ec4.appspot.com",
    messagingSenderId: "593897090690",
    appId: "1:593897090690:web:a9d683471fd004f21fce9b",
    measurementId: "G-N1RB11NVSN"
};

export default firebaseConfig;