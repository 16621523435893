import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useAuth from '../../contexts/useAuth';
import { useHistory, useLocation } from 'react-router-dom';

const Register = () => {

    const [loginData, setLoginData] = useState({});
    const { registerUser, isLoading, error, success } = useAuth();

    const location = useLocation();
    const history = useHistory();

    const handleOnClick = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newLoginData = {...loginData}
        newLoginData[field] = value;
        setLoginData(newLoginData);
        //console.log(newLoginData);
    }
    
    const handleOnSubmit = e => {
        if(loginData.password !== loginData.password2){
            alert('Your password did not match');
            //return;
        }
        registerUser(loginData.email, loginData.password, loginData.upCode, loginData.userRole, loginData.mobile, location, history);
        e.preventDefault();
    }

    return (
        <div>
            <div className="container p-3 mt-4 mb-4">
                <div className="row">
                    <div className="col-sm-2"></div>
                    <div className="col-sm-8">
                        <div className="card shadow">
                            <div className="card-header bg-dark text-light font-22">রেজিস্ট্রেশন করুন</div>
                            <div className="card-body">
                             <form onSubmit={handleOnSubmit}>
                                 {
                                     success && 
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        <strong>Registration Success</strong>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                   
                                 }
                                 {
                                     error &&
                                      <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                     <strong>{ error }</strong>
                                     <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                 </div>
                                 }
                                    <div className="row">
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">ইউনিয়ন কোড</label>
                                                <input type="text" onChange={handleOnClick} name="upCode" className="form-control" placeholder="Union Posrisod Code" required/>
                                            </div>
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">ইউজার রোল</label>
                                                <input type="text" onChange={handleOnClick} name="userRole" className="form-control" placeholder="User Role" required/>
                                            </div>
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">মোবাইল</label>
                                                <input type="number" onChange={handleOnClick} name="mobile" className="form-control" placeholder="Mobile"/>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">ইউজার ইমেইল</label>
                                                <input type="text" onChange={handleOnClick} name="email" className="form-control" placeholder="User Id / Email" style={{ fontFamily: 'arial'}} required/>
                                            </div>
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">পাসওয়ার্ড</label>
                                                <input type="password" onChange={handleOnClick} name="password" className="form-control" placeholder="Password" required/>
                                            </div>
                                            <div className="mb-3">
                                                <label for="exampleFormControlInput1" className="form-label">কনফার্ম পাসওয়ার্ড</label>
                                                <input type="password" onChange={handleOnClick} name="password2" className="form-control" placeholder="Confirm Password" required/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-3 d-grid gap-2 col-6 mx-auto">
                                        <button type='submit' className='btn btn-primary btn-block text-light'>
                                        {
                                            isLoading &&  <span className="spinner-border spinner-border-sm" style={{ marginRight: '10px' }} role="status" aria-hidden="true"></span> 
                                        }
                                             রেজিস্ট্রেশন </button>
                                    </div>
                                    <Link to="/login" className="nav-link text-center text-dark font-20">লগইন করুন</Link>
                                </form>
                            
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-2"></div>
                </div>
            </div>
        </div>
    );
};

export default Register;