import React, { useEffect, useState, useMemo } from "react";
import { TableHeader, Pagination, Search } from "../DataTable/";
import Nav from '../Nav/Nav';

const Data = () => {
    const [comments, setComments] = useState([]);
    const [dataCount, setCounter] = useState('10');
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

//------------------DataTable-------------------------------------------------
    const ITEMS_PER_PAGE = dataCount;
    const headers = [
        { name: "userId", field: "userId", sortable: false },
        { name: "id", field: "id", sortable: false },
        { name: "title", field: "title", sortable: true },
        { name: "body", field: "body", sortable: true },
        { name: "option", field: "", sortable: false },
    ];
    useEffect(() => {
        getData();
    },[]);

    function getData(){
        fetch("https://jsonplaceholder.typicode.com/posts")
                .then(response => response.json())
                .then(json => {
                    setComments(json);
        });
    }
    const commentsData = useMemo(() => {
        let computedComments = comments;
        if (search) {
            computedComments = computedComments.filter(
                comment =>
                   
                    comment.title.toLowerCase().includes(search.toLowerCase()) ||
                    comment.body.toLowerCase().includes(search.toLowerCase())
            );
        }
        setTotalItems(computedComments.length);
        //Sorting comments
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedComments = computedComments.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }
        //Current Page slice
        return computedComments.slice(
            (currentPage - 1) * ITEMS_PER_PAGE,
            (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
        );
    }, [comments, currentPage, search, sorting, ITEMS_PER_PAGE]);
//------------------DataTable--------------------------------------------------------

    return (
        <div>

            <Nav></Nav>

            <div className="container shadow mt-3 mb-3">

                <div className="d-grid gap-2 d-md-block">
                    <button className="btn mt-2 btn-success font-15" style={{ marginRight: '4px' }} type="button">Data table</button>
                </div> 


            {/*------------- Table Start -------------*/}
                <div className="mt-2 p-2"> 
                    <div className="row">
                        <div className="col mb-3 col-12 text-center">
                            <div className="row">
                                <div className="col-md-3 mb-2">
                                    <select className="form-select bangla-font" onClick={e => setCounter(e.target.value)} aria-label="Default select example">
                                        <option value="50">প্রতি পেজে ডাটার সংখা</option>
                                        <option value="20">20</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                                <div className="col-md-6"></div>
                                <div className="col-md-3 mb-2 d-flex flex-row-reverse">
                                    <Search
                                        onSearch={value => {
                                            setSearch(value);
                                            setCurrentPage(1);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">               
                                <table className="table table-striped mb-3" style={{ width:'100%' }}>
                                    <TableHeader
                                        headers={headers}
                                        onSorting={(field, order) =>
                                            setSorting({ field, order })
                                        }
                                    />
                                    <tbody>
                                        {commentsData.map(comment => (
                                            <tr>
                                                <td>{comment.userId}</td>
                                                <td>{comment.id}</td>
                                                <td>{comment.title}</td>
                                                <td>{comment.body}</td>
                                                <td> 
                                               
                                            </td> 
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>    
                            <div className="row">
                                <div className="col-md-6">
                                    <Pagination
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                                <div className="col-md-6 d-flex flex-row-reverse"></div>
                            </div>
                        </div>
                    </div>
                </div>
            {/*------------- Table Start -------------*/}

            </div>

        </div>
    );
};

export default Data;