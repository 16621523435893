import { useEffect, useState } from "react";
import initializeFirebase from "../component/Login/Firebase/Firebase.init";
import { getAuth, updatePassword, sendPasswordResetEmail, createUserWithEmailAndPassword, signOut, signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";

initializeFirebase();

const useFirebase = () =>{
    const [user, setUser] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [error, setAuthError] = useState(false);
    const [success, setAuthSuccess] = useState(false);
    const [success2, setAuthSuccess2] = useState(false);
    const [userData, setUserData] = useState('');
    const auth = getAuth();
      
    const registerUser = (email, password, upCode, userRole, mobile, location, history) => {
        setIsLoading(true);
        //logout();
        createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in 
          //const user = userCredential.user;
          saveUser(email, upCode, userRole, mobile);
          saveUp(upCode);
          //history.replace('/');
          // ...
          setAuthError(false);
          setAuthSuccess(true);
        })
        .catch((error) => {
          //const errorCode = error.code;
          //const errorMessage = error.message;
          setAuthError(error.message);
          setAuthSuccess(false);
          // ..
        })
        .finally(()=> setIsLoading(false));
    }

    const login = (email, password, location, history) =>{
        setIsLoading(true);
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          history.replace('/');
          // Signed in 
          //const user = userCredential.user;
          setAuthError(false);
          //setAuthSuccess(true);
          // ...
        })
        .catch((error) => {
          //const errorCode = error.code;
          //const errorMessage = error.message;
          setAuthError(error.message);
          setAuthSuccess('');
        })
        .finally(()=> setIsLoading(false));
    }

    const changePassword = (newPassword) =>{
      updatePassword(user, newPassword).then(() => {
        setAuthError(false);
        setAuthSuccess(true);
      }).catch((error) => {
        setAuthError(error.message);
        setAuthSuccess('');
      });
    }

    const resetPassword = (email) =>{
      sendPasswordResetEmail(auth, email)
        .then(() => {
          setAuthError(false);
          setAuthSuccess2(true);
        })
        .catch((error) => {
          setAuthError(error.message);
          setAuthSuccess2('');
        });
    }

    //observe user state
    useEffect(()=>{
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
              //const uid = user.uid;
              setUser(user);
            } else {
                setUser({});
            }
            setIsLoading(false);
          });
          return () =>  unsubscribe;
    },[auth])

    const logout = () => {
        setIsLoading(true);
        signOut(auth).then(() => {
            // Sign-out successful.
            setAuthSuccess(false);
            setAuthError(false);
          }).catch((error) => {
            // An error happened.
          })
          .finally(()=> setIsLoading(false));
    }

    const saveUser = (email, upCode, userRole, mobile) => {
      const user = { email, upCode, userRole, mobile };
      fetch('https://api-union.intelligentsoftech.com/users', {
          method: 'POST',
          headers: {
              'content-type': 'application/json'
          },
          body: JSON.stringify(user)
      })
      .then()
    }

    const saveUp = (upCode) => {
      const user = { upCode };
      fetch('https://api-union.intelligentsoftech.com/createUp', {
          method: 'POST',
          headers: {
              'content-type': 'application/json'
          },
          body: JSON.stringify(user)
      })
      .then()
    }

    useEffect(() => {
        fetch(`https://api-union.intelligentsoftech.com/users/${user.email}`)
        .then(res => res.json())
        .then(data => setUserData(data))
    },[user.email]);

    return{
        user,
        userData,
        registerUser,
        changePassword,
        resetPassword,
        login,
        logout,
        isLoading,
        error,
        success,
        success2,
    }
}

export default useFirebase;