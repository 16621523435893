import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import useAuth from '../../contexts/useAuth';

const Login = () => {

    const [loginData, setLoginData] = useState({});
    const { login, user, isLoading, error, success } = useAuth();

    const location = useLocation();
    const history = useHistory();

    const handleOnClick = e => {
        const field = e.target.name;
        const value = e.target.value;
        const newLoginData = {...loginData}
        newLoginData[field] = value;
        setLoginData(newLoginData);
        //console.log(newLoginData);
    }
    
    const handleOnSubmit = e => {
        login(loginData.email, loginData.password, location, history);
        e.preventDefault();
    }


    return (
        <div>
            <div className="container p-3 mt-4 mb-4">
                <div className="row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-4">
                        <div className="card shadow">
                            <div className="card-header bg-dark text-light font-22">লগইন করুন</div>
                            <div className="card-body">
                                <form onSubmit={handleOnSubmit}>
                                {
                                     success &&
                                    <div className="alert alert-success alert-dismissible fade show" role="alert">
                                        <strong>Login Success</strong>
                                        <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div>
                                   
                                 }
                                 {
                                     error &&
                                      <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                     <strong>{ error }</strong>
                                     <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                 </div>
                                 }
                                {
                                    user?.email &&
                                      
                                    <Redirect to='/'  />
                                    
                                } 
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">ইউজার আইডি / ইমেইল</label>
                                        <input type="text" onChange={handleOnClick} name="email" className="form-control" placeholder="User Id / Email" style={{ fontFamily: 'arial'}} required/>
                                    </div>
                                    <div className="mb-3">
                                        <label for="exampleFormControlInput1" className="form-label">পাসওয়ার্ড</label>
                                        <input type="password" onChange={handleOnClick} name="password" className="form-control" placeholder="Password" required/>
                                    </div>
                                    <div className="mb-3 d-grid gap-2 col-6 mx-auto">
                                        <button type='submit' className='btn btn-primary btn-block text-light'>
                                        {
                                            isLoading &&  <span className="spinner-border spinner-border-sm" style={{ marginRight: '10px' }} role="status" aria-hidden="true"></span> 
                                        }
                                            লগইন করুন</button>
                                    </div>
                                    
                                </form>
                                <div className="mb-3 d-grid gap-2 col-6 mx-auto">
                                    <Link to="/forget" style={{ textDecoration: 'none', textAlign: 'center' }}>Forget Password</Link>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4"></div>
                </div>
            </div>
        </div>
    );
};

export default Login;