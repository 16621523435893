import React from 'react';

const Footer = () => {
    return (
        <div className='text-center p-2'>
            {/* <p>Digital Union Porishod - 2021 | Developed and Maintenance by <Link to="https://intelligentsoftech.com" target="_blank">Intelligent Soft</Link> </p> */}
            <p className='text-light'>Digital Union Porishod - 2022 | Developed by <a href="https://intelligentsoftech.com" rel="noreferrer" style={{ textDecoration: 'none', color: 'orange' }} target="_blank">Intelligent Soft</a> </p>
        </div>
    );
};

export default Footer;