import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Nav from '../Nav/Nav';
import useAuth from '../../contexts/useAuth';
import toast, { Toaster } from 'react-hot-toast';

const VotarProfile = () => {
    const { userData } = useAuth();
    const { profileId }  = useParams();
    const [ profile, setProfile] = useState({});
    const [ seba, setSeba] = useState([]);
    const [ others, setOthers ] = useState([]);
    const [ words, setWords] = useState([]);
    const [ village, setVillage] = useState([]);

    const upId = userData.ID;

    const successNotify = () =>toast.success('Data Updated Successfully');
    const failNotify = () =>toast.error('Data Updated Fail');

    useEffect(() =>{
        getData();
    })

    function getData(){
        fetch(`https://api-union.intelligentsoftech.com/profile/${profileId}`)
        //fetch(`http://localhost:5000/profile/${profileId}`)
        .then(res=>res.json())
        .then(data=>setProfile(data));
    }

    useEffect(() =>{
        fetch(`https://api-union.intelligentsoftech.com/vataSohaotaSingle/${profileId}`)
        .then(res=>res.json())
        .then(data=>setSeba(data));
    })

    useEffect(() =>{
        fetch(`https://api-union.intelligentsoftech.com/othersDataSingle/${profileId}`)
        .then(res=>res.json())
        .then(data=>setOthers(data));
    })

    //get word name
    useEffect(() => {
        getWordData();
    });
    
    function getWordData(){
        fetch(`https://api-union.intelligentsoftech.com/upWordName/${upId}`)
        .then(response => response.json())
        .then(json => setWords(json));
    }
    
    useEffect(() => {
         getVillageData();
    });
    
    function getVillageData() {
        fetch(`https://api-union.intelligentsoftech.com/upVillageName/${upId}`)
        .then(response => response.json())
        .then(json => setVillage(json));
    }

    const handleVotarDataUpdate = e => {
        e.preventDefault();
        //votarSerial votarName fatherName motherName gender wordName village mobile nid dob
        const id = e.target.elements.id.value;
        const sl = e.target.elements.votarSerial.value;
        const votarName = e.target.elements.votarName.value;
        const fatherName = e.target.elements.fatherName.value;
        const motherName = e.target.elements.motherName.value;
        const gender = e.target.elements.gender.value;
        const wordName = e.target.elements.wordName.value;
        const village = e.target.elements.village.value;
        const mobile = e.target.elements.mobile.value;
        const nid = e.target.elements.nid.value;
        const dob = e.target.elements.dob.value;

        const infoData = {
            sl,
            id,
            votarName,
            fatherName,
            motherName,
            gender,
            wordName,
            village,
            mobile,
            nid,
            dob,
        }

        fetch('https://api-union.intelligentsoftech.com/upVotarData', {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(infoData)
        })
        .then(res=>res.json())
        .then(data=>{
            if(data.acknowledged){
                //e.target.reset();
                successNotify();
                getData();
               
            }else{
                failNotify();
            }
        })
    }

    let count1 = 1;
    let count2 = 1;


    return (
        <div>
            <Nav></Nav>

            <Toaster
                position='top-right'
            />

      
        
                <div className="container mt-2 mb-4">
                    <div className="row">
                        <div className="col-sm-3">
                            <div className="card shadow p-3" style={{ height: '390px' }}>
                                <div className="card-body">
                                    <img src="https://media.istockphoto.com/photos/businessman-silhouette-as-avatar-or-default-profile-picture-picture-id476085198?k=20&m=476085198&s=170667a&w=0&h=FXkT-N6vISLOCUefa9MyQg0pH-6loMX9zBZjgLK458c=" alt="" style={{ width: '200px', height: '230px' }} />
                                    <hr/>
                                    <h4 className='text-center'>{ profile.votarName }</h4>
                                    <div className="d-flex justify-content-center">
                                        <button className='btn btn-sm btn-danger text-light' data-bs-toggle="modal" data-bs-target="#exampleModal">Update Profile</button>
                                    </div>   
                                </div> 
                            </div>
                        </div>
                        <div className="col-sm-9">
                            <div className="card shadow" style={{ height: '390px' }}>
                                <div className="card-header bg-dark text-light font-20">ভোটার তথ্য বিস্তারিত </div>
                                <div className="">
                                    <div className="table-responsive">
                                       
                                                <table className="table table-striped" key={profile._id}>   
                                                    <tbody>
                                                        <tr>
                                                            <td>ভোটার সিরিয়াল</td>
                                                            <td>{ profile.votarSerial }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>ভোটারের নাম</td>
                                                            <td>{ profile.votarName }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>পিতার নাম</td>
                                                            <td>{ profile.fatherName }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>মাতার নাম</td>
                                                            <td>{ profile.motherName }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>গ্রাম</td>
                                                            <td>{ profile.village }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>মোবাইল</td>
                                                            <td>{ profile.mobile }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>NID</td>
                                                            <td>{ profile.nid }</td>
                                                        </tr>
                                                        <tr>
                                                            <td>জন্ম তারিখ</td>
                                                            <td>{ profile.dob }</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card shadow mt-2">
                        <div className="card-header bg-dark text-light font-18">ভাতা/সহায়তা প্রাপ্ত তালিকা</div>
                            <div className="table-responsive">
                                <table className="table table-striped font-16">
                                    <thead>
                                        <tr>
                                            <th scope="col">ক্রমিক</th>
                                            <th scope="col">ভাতা/সহায়তা ক্যাটাগরি</th>
                                            <th scope="col">ভাতা/সহায়তা মেয়াদ</th>
                                            <th scope="col">ভাতা/সহায়তা প্রদান তারিখ</th>
                                            <th scope="col">চেয়ারম্যন</th>
                                            <th scope="col">অন্যান্য তথ্য</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                           seba.map((row)=>(
                                            <tr key={row._id}>
                                                <th scope="row">{ count1++ }</th>
                                                <td>{ row.category }</td>
                                                <td>{ row.expiryDate }</td>
                                                <td>{ row.entryDate }</td>
                                                <td>{ row.chairmanName }</td>
                                                <td>{ row.othersInfo }</td>
                                            </tr>  
                                           )) 
                                            
                                        }
                                    
                                    </tbody>
                                </table>
                            </div>
                    </div>


                    <div className="card shadow mt-2">
                        <div className="card-header bg-dark text-light font-18">বিভিন্ন তালিকা</div>
                        <div className="table-responsive">
                            <table className="table table-striped font-16">
                                    <thead>
                                        <tr>
                                            <th scope="col">ক্রমিক</th>
                                            <th scope="col">ক্যাটাগরি</th>
                                            <th scope="col">মেয়াদ</th>
                                            <th scope="col">প্রদান তারিখ</th>
                                            <th scope="col">চেয়ারম্যন</th>
                                            <th scope="col">অন্যান্য তথ্য</th>
                                        </tr>
                                    </thead>
                                <tbody>
                                        {
                                            others.map((row2)=>(
                                                <tr key={row2._id}>
                                                    <th scope="row">{ count2++ }</th>
                                                    <td>{ row2.category }</td>
                                                    <td>{ row2.expiryDate }</td>
                                                    <td>{ row2.entryDate }</td>
                                                    <td>{ row2.chairmanName }</td>
                                                    <td>{ row2.othersInfo }</td>
                                                </tr> 
                                            ))
                                        }
                                      
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                </div>

       

                <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-scrollable">
                    <div className="modal-content">
                    <div className="modal-header bg-primary text-light">
                        <h5 className="modal-title" id="exampleModalLabel">ভোটার তথ্য আপডেট করুন</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    
                    <div className="modal-body">
                          
                                    <form onSubmit={handleVotarDataUpdate}>        
                                        <div className="mb-1">
                                            <label for="recipient-name" className="col-form-label">ভোটার সিরিয়াল</label>
                                            <input type="text" defaultValue={profile.votarSerial} className="form-control" name="votarSerial" placeholder="ভোটার সিরিয়াল"/>
                                        </div>
                                        <div className="mb-1">
                                            <label for="recipient-name" className="col-form-label">ভোটারের নাম</label>
                                            <input type="text" defaultValue={profile.votarName} className="form-control" name="votarName" placeholder="ভোটারের নাম" required/>
                                        </div>
                                        <div className="mb-1">
                                            <label for="recipient-name" className="col-form-label">পিতার নাম</label>
                                            <input type="text" defaultValue={profile.fatherName} className="form-control" name="fatherName" placeholder="পিতার নাম" required/>
                                        </div>
                                        <div className="mb-1">
                                            <label for="recipient-name" className="col-form-label">মাতার নাম</label>
                                            <input type="text" defaultValue={profile.motherName} className="form-control" name="motherName" placeholder="মাতার নাম"/>
                                        </div>
                                        <div className="mb-1">
                                            <label for="recipient-name" className="col-form-label">পুরুষ / মহিলা</label>
                                            <select className="form-select" defaultValue={profile.gender} name="gender" aria-label="Default select example" required>
                                                <option selected>{profile.gender}</option>
                                                <option value="পুরুষ">পুরুষ</option>
                                                <option value="মহিলা">মহিলা</option>
                                            </select>
                                        </div>
                                        <div className="mb-1">
                                            <label for="recipient-name" className="col-form-label">ওয়ার্ড নং</label>
                                            <select className="form-select" defaultValue={profile.wordName} name="wordName" aria-label="Default select example" required>
                                            <option selected>{profile.wordName}</option> 
                                            {
                                                words.map((row)=>(
                                                    <option value={row.wordName}>{ row.wordName }</option>
                                                ))
                                            }   
                                            </select>
                                        </div>
                                        <div className="mb-1">
                                            <label for="recipient-name" className="col-form-label">গ্রাম</label>
                                            <select className="form-select" defaultValue={profile.village} name="village" aria-label="Default select example" required>
                                            <option selected>{profile.village}</option> 
                                            {
                                                village.map((row)=>(
                                                    <option value={row.villageName}>{ row.villageName }</option>
                                                ))
                                            }   
                                            </select>
                                        </div>
                                    
                                        <div className="mb-1">
                                            <label for="recipient-name" className="col-form-label">মোবাইল</label>
                                            <input type="text" defaultValue={profile.mobile} className="form-control" name="mobile" placeholder="মোবাইল"/>
                                        </div>
                                        <div className="mb-1">
                                            <label for="recipient-name" className="col-form-label">NID</label>
                                            <input type="text" defaultValue={profile.nid}className="form-control" name="nid" placeholder="NID"/>
                                        </div>
                                        <div className="mb-1">
                                            <label for="recipient-name" className="col-form-label">জন্ম তারিখ</label>
                                            <input type="text" defaultValue={profile.dob} className="form-control" name="dob" placeholder="dd/mm/yy"/>
                                        </div>
                                        <input type="hidden" name="id" defaultValue={profile._id}/>
                                        <button type="submit" className="btn btn-block btn-primary mt-2 p-2">আপডেট করুন</button>
                                    </form>
                           
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    </div>
                    </div>
                </div>
                </div>
        </div>
    );
};

export default VotarProfile;